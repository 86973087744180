@charset "UTF-8";
/* ------------------------------------------------------------------------------
 *
 *  # Bootstrap v4.3.1 (https://getbootstrap.com)
 *
 *  Copyright 2011-2018 The Bootstrap Authors
 *  Copyright 2011-2018 Twitter, Inc.
 *  Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Global configuration
 *
 *  Here you can change main theme, enable or disable certain components and
 *  optional styles. This allows you to include only components that you need.
 *
 *  'true'  - enables component and includes it to main CSS file.
 *  'false' - disables component and excludes it from main CSS file.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom Limitless functions
 *
 *  Utility mixins and functions for evalutating source code across our variables, maps, and mixins.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom template mixins
 *
 *  All custom mixins are prefixed with "ll-" to avoid conflicts
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Default Bootstrap variable overrides
 *
 *  Variables should follow the `$component-state-property-size` formula for
 *  consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 *  Also includes custom variables, all marked with "!default" flag.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Additional variables
 *
 *  Mainly 3rd party libraries and additional variables for default
 *  Bootstrap components.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Mixins
 *
 *  Import Bootstrap mixins with overrides
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Buttons mixin
 *
 *  Override and extend default buttons mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Caret mixin
 *
 *  Override and extend default cared mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Background mixin
 *
 *  Override and extend default background mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Reboot
 *
 *  Normalization of HTML elements - overrides of default Bootstrap styles
 *
 * ---------------------------------------------------------------------------- */
body {
  position: relative; }

dt {
  margin-bottom: 0.3125rem; }

dd {
  margin-bottom: 1.25rem; }
  dd:last-child {
    margin-bottom: 0; }

dl:last-child {
  margin-bottom: 0; }

pre {
  -moz-tab-size: 4;
    -o-tab-size: 4;
       tab-size: 4;
  border: 1px solid #ddd;
  padding: 1.25rem 1.25rem;
  margin-bottom: 0;
  -webkit-hyphens: none;
     -moz-hyphens: none;
      -ms-hyphens: none;
          hyphens: none; }

svg {
  display: block; }
  .svg-inline svg {
    display: inline-block; }
  .svg-center svg {
    margin: auto; }

input[type="file"] {
  display: block; }

@media (min-width: 769px) {
  .custom-scrollbars * {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: thin;
    scrollbar-color: #999 #eee; }
  .custom-scrollbars ::-webkit-scrollbar {
    height: 0.4rem;
    width: 0.4rem; }
  .custom-scrollbars ::-webkit-scrollbar-track {
    background: #eee; }
  .custom-scrollbars ::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 0.4rem; } }

/* ------------------------------------------------------------------------------
 *
 *  # Text styling
 *
 *  Override and extend default Bootstrap styles.
 *
 * ---------------------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  letter-spacing: -0.015em; }

.list > li,
.list > li .list > li {
  margin-top: 0.3125rem; }

.list > li:first-child {
  margin-top: 0; }

.list-extended > li,
.list-extended > li .list > li {
  margin-top: 0.625rem; }

ul.list-square {
  list-style-type: square; }

ul.list-circle {
  list-style-type: circle; }

.list-inline-item {
  position: relative; }

.list-inline-condensed .list-inline-item:not(:last-child) {
  margin-right: 0.625rem; }

.list-inline-dotted .list-inline-item:not(:last-child) {
  margin-right: 0.625rem; }
  .list-inline-dotted .list-inline-item:not(:last-child):after {
    content: "";
    font-family: "icomoon";
    font-size: 0.5rem;
    width: 0.4rem;
    text-align: center;
    display: inline-block;
    margin-left: 0.825rem;
    opacity: 0.25; }

.list-inline-dotted.list-inline-condensed .list-inline-item:not(:last-child) {
  margin-right: 0.41667rem; }
  .list-inline-dotted.list-inline-condensed .list-inline-item:not(:last-child):after {
    margin-left: 0.54167rem; }

.list-scrollspy .nav-link {
  color: #333; }
  .list-scrollspy .nav-link:hover, .list-scrollspy .nav-link:focus {
    color: #2196F3; }
  .list-scrollspy .nav-link.active {
    border-color: #2196F3;
    color: #2196F3; }

.list-scrollspy .list-scrollspy {
  padding-left: 1.25rem; }
  .list-scrollspy .list-scrollspy .list-scrollspy {
    padding-left: 1.25rem; }

.list-icons {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center; }
  .list-icons > *:not(:first-child) {
    margin-left: 0.625rem; }
  .list-icons-extended > *:not(:first-child) {
    margin-left: 1.25rem; }

a.list-icons-item {
  display: inline-block;
  transition: color ease-in-out 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    a.list-icons-item {
      transition: none; } }
  a.list-icons-item:not([class*=text-]) {
    color: inherit; }
  a.list-icons-item > i {
    top: 0; }
  a.list-icons-item:hover, a.list-icons-item:focus,
  .show > a.list-icons-item {
    outline: 0;
    opacity: 0.75; }
  a.list-icons-item[data-toggle="collapse"] i {
    transition: -webkit-transform ease-in-out 0.15s;
    transition: transform ease-in-out 0.15s;
    transition: transform ease-in-out 0.15s, -webkit-transform ease-in-out 0.15s; }
  a.list-icons-item.collapsed i {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

.font-weight-black {
  font-weight: 900; }

.font-weight-semibold {
  font-weight: 500; }

.font-weight-thin {
  font-weight: 100; }

.blockquote-bordered {
  border-left: 5px solid #eee; }
  .blockquote-bordered-reverse {
    border-left: 0;
    border-right: 5px solid #eee; }

.blockquote-footer {
  font-size: 0.875rem; }
  .blockquote-footer::before {
    content: "\2014 \00A0 \00A0 \00A0"; }

.list-feed-item {
  position: relative;
  padding-bottom: 1.25rem;
  padding-left: 1.75rem; }
  .list-feed-item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.375rem;
    background-color: #fff;
    display: inline-block;
    border: 2px solid #45748a;
    z-index: 3;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%; }
  .list-feed-item:after {
    content: '';
    position: absolute;
    top: 0.375rem;
    left: 0.1875rem;
    bottom: -0.5rem;
    width: 0;
    border-left: 1px solid #45748a;
    border-right: 1px solid #45748a;
    z-index: 2; }
  .list-feed-item:first-child:after {
    top: 0.5rem; }
  .list-feed-item:last-child {
    padding-bottom: 0; }
    .list-feed-item:last-child:after {
      content: none; }
  .list-feed-item[class*=border-]:before, .list-feed-item[class*=border-]:after {
    border-color: inherit; }

.list-feed-solid .list-feed-item:before {
  content: '';
  border-width: 0.25rem;
  width: 0;
  height: 0; }

.list-feed-square .list-feed-item:before {
  content: '';
  border-radius: 0; }

.list-feed-rhombus .list-feed-item:before {
  content: '';
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 0; }

.list-feed-time .list-feed-item {
  margin-left: 3.25rem; }
  .list-feed-time .list-feed-item .feed-time {
    position: absolute;
    left: -3.25rem;
    top: 0; }

.content-divider {
  text-align: center;
  position: relative;
  z-index: 1; }
  .content-divider > span {
    background-color: #f5f5f5;
    display: inline-block; }
    .card .content-divider > span,
    .tab-content-bordered .content-divider > span {
      background-color: #fff; }
    .modal .content-divider > span {
      background-color: #fff; }
    .content-divider > span:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      height: 1px;
      background-color: #ddd;
      width: 100%;
      z-index: -1; }

.letter-icon {
  width: 1rem;
  height: 1.375rem;
  display: block; }
  .btn-lg .letter-icon {
    height: 1.375rem; }
  .btn-sm .letter-icon {
    height: 1.375rem; }

/* ------------------------------------------------------------------------------
 *
 *  # Code blocks
 *
 *  Overrides of default code block styles
 *
 * ---------------------------------------------------------------------------- */
code {
  padding: 0.2rem 0.4rem;
  background-color: #fafafa; }

pre {
  white-space: pre-wrap;
  /*rtl:ignore*/
  text-align: left;
  border-radius: 0.25rem; }

/* ------------------------------------------------------------------------------
 *
 *  # Table styling
 *
 *  Override and extend default Bootstrap styles.
 *
 * ---------------------------------------------------------------------------- */
.table {
  margin-bottom: 0; }
  .table th,
  .table td {
    vertical-align: middle; }
  .table th {
    font-weight: 500; }
  .table thead th {
    vertical-align: middle;
    border-bottom: 1px solid #b7b7b7; }
  .table tfoot th {
    border-top-color: #b7b7b7; }
  .table caption + thead tr:first-child th,
  .table caption + thead tr:first-child td,
  .table colgroup + thead tr:first-child th,
  .table colgroup + thead tr:first-child td,
  .table thead:first-child tr:first-child th,
  .table thead:first-child tr:first-child td {
    border-top-width: 0; }
  .table tr,
  .table th,
  .table td {
    transition: background-color ease-in-out 0.15s; }
    @media (prefers-reduced-motion: reduce) {
      .table tr,
      .table th,
      .table td {
        transition: none; } }

.table-dark thead th,
.table-dark thead td {
  border-bottom-color: rgba(255, 255, 255, 0.5); }

.table-dark tfoot th,
.table-dark tfoot td {
  border-top-color: rgba(255, 255, 255, 0.5); }

@media (max-width: 575.98px) {
  .table-responsive-sm > .table-bordered {
    border: 1px solid #ddd; } }

@media (max-width: 768.98px) {
  .table-responsive-md > .table-bordered {
    border: 1px solid #ddd; } }

@media (max-width: 991.98px) {
  .table-responsive-lg > .table-bordered {
    border: 1px solid #ddd; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl > .table-bordered {
    border: 1px solid #ddd; } }

.table-responsive > .table-bordered {
  border: 1px solid #ddd; }

.table-scrollable {
  max-height: 22.5rem;
  overflow: auto; }

.table-columned tbody td,
.table-columned tbody th,
.table-columned tfoot td,
.table-columned tfoot th {
  border: 0;
  border-left: 1px solid #ddd; }
  .table-columned tbody td:first-child,
  .table-columned tbody th:first-child,
  .table-columned tfoot td:first-child,
  .table-columned tfoot th:first-child {
    border-left: 0; }

.table-columned thead th,
.table-columned thead td {
  border-left: 1px solid #ddd; }
  .table-columned thead th:first-child,
  .table-columned thead td:first-child {
    border-left: 0; }

.table-xl th,
.table-xl td {
  padding: 1.25rem 1.25rem; }

.table-lg th,
.table-lg td {
  padding: 1rem 1.25rem; }

.table-xs th,
.table-xs td {
  padding: 0.5rem 1.25rem; }

thead .table-border-solid th,
thead .table-border-solid td {
  border-bottom-width: 2px; }

tbody .table-border-solid td,
tbody .table-border-solid th,
tfoot .table-border-solid td,
tfoot .table-border-solid th {
  border-top-width: 2px; }

thead .table-border-double th,
thead .table-border-double td {
  border-bottom-width: 3px;
  border-bottom-style: double; }

.table-bordered thead .table-border-double:first-child th,
.table-bordered thead .table-border-double:first-child td {
  border-bottom-width: 3px;
  border-bottom-style: double; }

tbody .table-border-double td,
tbody .table-border-double th,
tfoot .table-border-double td,
tfoot .table-border-double th {
  border-top-width: 3px;
  border-top-style: double; }

.table-border-dashed td,
.table-border-dashed th {
  border-top-style: dashed; }

.table thead tr[class*=border-bottom-] th,
.table thead tr[class*=border-bottom-] td {
  border-bottom-color: inherit; }

.table tbody tr[class*=border-top-] th,
.table tbody tr[class*=border-top-] td,
.table tfoot tr[class*=border-top-] th,
.table tfoot tr[class*=border-top-] td {
  border-top-color: inherit; }

.card > .table:first-child caption + thead tr:first-child th,
.card > .table:first-child caption + thead tr:first-child td,
.card > .table:first-child colgroup + thead tr:first-child th,
.card > .table:first-child colgroup + thead tr:first-child td,
.card > .table:first-child thead:first-child tr:first-child th,
.card > .table:first-child thead:first-child tr:first-child td,
.card > [class*=table-responsive]:first-child > .table:first-child caption + thead tr:first-child th,
.card > [class*=table-responsive]:first-child > .table:first-child caption + thead tr:first-child td,
.card > [class*=table-responsive]:first-child > .table:first-child colgroup + thead tr:first-child th,
.card > [class*=table-responsive]:first-child > .table:first-child colgroup + thead tr:first-child td,
.card > [class*=table-responsive]:first-child > .table:first-child thead:first-child tr:first-child th,
.card > [class*=table-responsive]:first-child > .table:first-child thead:first-child tr:first-child td {
  border-top-width: 0; }

.card.card-table {
  border-width: 1px;
  border-color: #ddd; }

.card > .table caption,
.card > [class*=table-responsive] > .table caption {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.card > .table:first-child,
.card > [class*=table-responsive]:first-child > .table:first-child {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .card > .table:first-child thead:first-child tr:first-child,
  .card > .table:first-child tbody:first-child tr:first-child,
  .card > [class*=table-responsive]:first-child > .table:first-child thead:first-child tr:first-child,
  .card > [class*=table-responsive]:first-child > .table:first-child tbody:first-child tr:first-child {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
    .card > .table:first-child thead:first-child tr:first-child td:first-child,
    .card > .table:first-child thead:first-child tr:first-child th:first-child,
    .card > .table:first-child tbody:first-child tr:first-child td:first-child,
    .card > .table:first-child tbody:first-child tr:first-child th:first-child,
    .card > [class*=table-responsive]:first-child > .table:first-child thead:first-child tr:first-child td:first-child,
    .card > [class*=table-responsive]:first-child > .table:first-child thead:first-child tr:first-child th:first-child,
    .card > [class*=table-responsive]:first-child > .table:first-child tbody:first-child tr:first-child td:first-child,
    .card > [class*=table-responsive]:first-child > .table:first-child tbody:first-child tr:first-child th:first-child {
      border-top-left-radius: calc(0.25rem - 1px); }
    .card > .table:first-child thead:first-child tr:first-child td:last-child,
    .card > .table:first-child thead:first-child tr:first-child th:last-child,
    .card > .table:first-child tbody:first-child tr:first-child td:last-child,
    .card > .table:first-child tbody:first-child tr:first-child th:last-child,
    .card > [class*=table-responsive]:first-child > .table:first-child thead:first-child tr:first-child td:last-child,
    .card > [class*=table-responsive]:first-child > .table:first-child thead:first-child tr:first-child th:last-child,
    .card > [class*=table-responsive]:first-child > .table:first-child tbody:first-child tr:first-child td:last-child,
    .card > [class*=table-responsive]:first-child > .table:first-child tbody:first-child tr:first-child th:last-child {
      border-top-right-radius: calc(0.25rem - 1px); }

.card > .table:last-child,
.card > [class*=table-responsive]:last-child > .table:last-child {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .table:last-child tbody:last-child tr:last-child,
  .card > .table:last-child tfoot:last-child tr:last-child,
  .card > [class*=table-responsive]:last-child > .table:last-child tbody:last-child tr:last-child,
  .card > [class*=table-responsive]:last-child > .table:last-child tfoot:last-child tr:last-child {
    border-bottom-left-radius: calc(0.25rem - 1px);
    border-bottom-right-radius: calc(0.25rem - 1px); }
    .card > .table:last-child tbody:last-child tr:last-child td:first-child,
    .card > .table:last-child tbody:last-child tr:last-child th:first-child,
    .card > .table:last-child tfoot:last-child tr:last-child td:first-child,
    .card > .table:last-child tfoot:last-child tr:last-child th:first-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tbody:last-child tr:last-child td:first-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tbody:last-child tr:last-child th:first-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tfoot:last-child tr:last-child td:first-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tfoot:last-child tr:last-child th:first-child {
      border-bottom-left-radius: calc(0.25rem - 1px); }
    .card > .table:last-child tbody:last-child tr:last-child td:last-child,
    .card > .table:last-child tbody:last-child tr:last-child th:last-child,
    .card > .table:last-child tfoot:last-child tr:last-child td:last-child,
    .card > .table:last-child tfoot:last-child tr:last-child th:last-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tbody:last-child tr:last-child td:last-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tbody:last-child tr:last-child th:last-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tfoot:last-child tr:last-child td:last-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tfoot:last-child tr:last-child th:last-child {
      border-bottom-right-radius: calc(0.25rem - 1px); }

.card > .table-bordered,
.card > [class*=table-responsive] > .table-bordered {
  border: 0; }
  .card > .table-bordered thead th:first-child,
  .card > .table-bordered thead td:first-child,
  .card > .table-bordered tbody th:first-child,
  .card > .table-bordered tbody td:first-child,
  .card > .table-bordered tfoot th:first-child,
  .card > .table-bordered tfoot td:first-child,
  .card > [class*=table-responsive] > .table-bordered thead th:first-child,
  .card > [class*=table-responsive] > .table-bordered thead td:first-child,
  .card > [class*=table-responsive] > .table-bordered tbody th:first-child,
  .card > [class*=table-responsive] > .table-bordered tbody td:first-child,
  .card > [class*=table-responsive] > .table-bordered tfoot th:first-child,
  .card > [class*=table-responsive] > .table-bordered tfoot td:first-child {
    border-left: 0; }
  .card > .table-bordered thead th:last-child,
  .card > .table-bordered thead td:last-child,
  .card > .table-bordered tbody th:last-child,
  .card > .table-bordered tbody td:last-child,
  .card > .table-bordered tfoot th:last-child,
  .card > .table-bordered tfoot td:last-child,
  .card > [class*=table-responsive] > .table-bordered thead th:last-child,
  .card > [class*=table-responsive] > .table-bordered thead td:last-child,
  .card > [class*=table-responsive] > .table-bordered tbody th:last-child,
  .card > [class*=table-responsive] > .table-bordered tbody td:last-child,
  .card > [class*=table-responsive] > .table-bordered tfoot th:last-child,
  .card > [class*=table-responsive] > .table-bordered tfoot td:last-child {
    border-right: 0; }

.card-header:not([class*=bg-]) + .table,
.card-header:not([class*=bg-]) + div[class*=table-responsive],
.card-body + .table,
.card-body + div[class*=table-responsive],
.table + .card-body,
div[class*=table-responsive] + .card-body,
.navbar-light + div[class*=table-responsive],
.navbar-light + .table {
  border-top: 1px solid #ddd; }

.table tbody:first-child tr:first-child th,
div[class*=table-responsive] > .table tbody:first-child tr:first-child th,
.table tbody:first-child tr:first-child td,
div[class*=table-responsive] > .table tbody:first-child tr:first-child td {
  border-top: 0; }

.card-footer + [class*=table-responsive],
.card-footer + .table {
  border-top: 1px solid #ddd; }

/* ------------------------------------------------------------------------------
 *
 *  # Form styles
 *
 *  Basic form styles and overrides of default Bootstrap styles
 *
 * ---------------------------------------------------------------------------- */
legend {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #ddd;
  font-size: 0.875rem; }
  fieldset:first-child legend:first-child {
    padding-top: 0; }

select[multiple],
select[size] {
  height: 200px;
  padding: 0.875rem; }
  select[multiple] option,
  select[size] option {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem; }
    select[multiple] option + option,
    select[size] option + option {
      margin-top: 1px; }

.form-control {
  background-clip: border-box; }
  .form-control:disabled {
    color: #999; }

.form-group .form-group:last-child {
  margin-bottom: 0; }

@media (max-width: 768.98px) {
  .form-group [class*="col-md-"]:not([class*=col-form-label]) + [class*="col-md-"] {
    margin-top: 1.25rem; } }

@media (max-width: 991.98px) {
  .form-group [class*="col-lg-"]:not([class*=col-form-label]) + [class*="col-lg-"] {
    margin-top: 1.25rem; } }

@media (max-width: 1199.98px) {
  .form-group [class*="col-xl-"]:not([class*=col-form-label]) + [class*="col-xl-"] {
    margin-top: 1.25rem; } }

@media (max-width: 768.98px) {
  [class*=col-form-label][class*="col-md-"] {
    padding-top: 0; } }

@media (max-width: 991.98px) {
  [class*=col-form-label][class*="col-lg-"] {
    padding-top: 0; } }

@media (max-width: 1199.98px) {
  [class*=col-form-label][class*="col-xl-"] {
    padding-top: 0; } }

.form-check.disabled {
  color: #999; }
  .form-check.disabled .form-check-label {
    cursor: default; }

.form-check-horizontal {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.form-check-input:disabled ~ .form-check-label {
  cursor: default; }

.form-check-label {
  cursor: pointer; }

.form-check-inline .form-check-label {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center; }

.form-check-inline:last-child {
  margin-right: 0; }

.form-check-right {
  padding-left: 0;
  padding-right: 1.875rem; }
  .form-check-right .form-check-input,
  .form-check-right input {
    left: auto;
    right: 0; }
  .form-check-right.form-check-inline {
    padding: 0; }
    .form-check-right.form-check-inline .form-check-input {
      margin-right: 0;
      margin-left: 0.625rem; }

.form-group-feedback {
  position: relative; }
  .form-group-feedback-left .form-control-feedback {
    left: 0; }
  .form-group-feedback-left .form-control {
    padding-left: 2.75rem; }
    .form-group-feedback-left .form-control-lg {
      padding-left: 3rem; }
    .form-group-feedback-left .form-control-sm {
      padding-left: 2.5rem; }
  .form-group-feedback-right .form-control-feedback {
    right: 0; }
  .form-group-feedback-right .form-control {
    padding-right: 2.75rem; }
    .form-group-feedback-right .form-control-lg {
      padding-right: 3rem; }
    .form-group-feedback-right .form-control-sm {
      padding-right: 2.5rem; }

.form-control-feedback {
  position: absolute;
  top: 0;
  color: #333;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  line-height: calc(1.5715em + 0.875rem + 2px);
  min-width: 1rem; }
  .form-control-feedback-lg {
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: calc(1.4667em + 1.125rem + 2px); }
  .form-control-feedback-sm {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    line-height: calc(1.6924em + 0.625rem + 2px); }

/* ------------------------------------------------------------------------------
 *
 *  # Button component
 *
 *  Overrides for button bootstrap component
 *
 * ---------------------------------------------------------------------------- */
.btn {
  position: relative; }
  .btn::-moz-focus-inner {
    border: 0; }
  .btn.disabled, .btn:disabled {
    cursor: default; }

.btn-light {
  color: #333;
  background-color: #f0f2f5;
  border-color: #cad1dc;
  }
  .btn-light:hover {
    color: #333;
    background-color: #e1e5eb;
    border-color: #bac4d1; }
  .btn-light:focus, .btn-light.focus {
    color: #333;
    background-color: #e1e5eb;
    border-color: #bac4d1;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(179, 185, 194, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #333;
    background-color: #f0f2f5;
    border-color: #cad1dc; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light {
    color: #333;
    background-color: #d1d7e1;
    border-color: #abb6c7; }

.show > .btn-link.dropdown-toggle {
  color: #0a6ebd; }

.btn-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0;
  width: calc(2.25rem + 2px);
  height: calc(2.25rem + 2px); }
  .btn-icon > i {
    position: static;
    min-width: 1rem; }
  .btn-icon input[type=checkbox],
  .btn-icon input[type=radio] {
    display: block; }
  .btn-icon.btn-sm {
    width: calc(2rem + 2px);
    height: calc(2rem + 2px); }
  .btn-icon.btn-lg {
    width: calc(2.5rem + 2px);
    height: calc(2.5rem + 2px); }

.btn-float {
  padding: 1rem;
  border-radius: 0.25rem; }
  .btn-float.btn-link {
    padding: 0.8rem; }
  .btn-float i {
    display: block;
    top: 0; }
  .btn-float > span {
    display: block;
    padding-top: 0.625rem;
    margin-bottom: -0.4375rem;
    overflow: hidden;
    text-overflow: ellipsis; }

.btn.border-2 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem; }
  .btn.border-2.btn-lg {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .btn.border-2.btn-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .btn.border-2.btn-icon {
    width: calc(2.25rem + 4px);
    height: calc(2.25rem + 4px); }
    .btn.border-2.btn-icon.btn-sm {
      width: calc(2rem + 4px);
      height: calc(2rem + 4px); }
    .btn.border-2.btn-icon.btn-lg {
      width: calc(2.5rem + 4px);
      height: calc(2.5rem + 4px); }
  .btn.border-2.btn-float {
    padding: 0.9375rem; }

.btn-active-indicator {
  opacity: 0;
  visibility: hidden;
  transition: opacity ease-in-out 0.15s, visibility ease-in-out 0.15s; }
  .btn.active > .btn-active-indicator {
    opacity: 1;
    visibility: visible; }

.btn-labeled > b {
  position: absolute;
  top: -1px;
  background-color: rgba(0, 0, 0, 0.15);
  display: block;
  line-height: 1;
  padding: 0.6875rem; }
  .btn-labeled > b > i {
    top: 0; }

.btn-labeled.rounded-pill > b {
  border-radius: 50rem; }

.btn-labeled.btn-lg > b {
  padding: 0.8125rem; }

.btn-labeled.btn-sm > b {
  padding: 0.5625rem; }

.btn-labeled-left {
  padding-left: 3.25rem; }
  .btn-labeled-left > b {
    left: -1px;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .btn-labeled-left.btn-lg {
    padding-left: 3.625rem; }
    .btn-labeled-left.btn-lg > b {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem; }
  .btn-labeled-left.btn-sm {
    padding-left: 2.875rem; }
    .btn-labeled-left.btn-sm > b {
      border-top-left-radius: 0.1875rem;
      border-bottom-left-radius: 0.1875rem; }

.btn-labeled-right {
  padding-right: 3.375rem; }
  .btn-labeled-right > b {
    right: -1px;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }
  .btn-labeled-right.btn-lg {
    padding-right: 3.625rem; }
    .btn-labeled-right.btn-lg > b {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0.3125rem; }
  .btn-labeled-right.btn-sm {
    padding-right: 2.875rem; }
    .btn-labeled-right.btn-sm > b {
      border-top-right-radius: 0.1875rem;
      border-bottom-right-radius: 0.1875rem; }

/* ------------------------------------------------------------------------------
 *
 *  # Dropdown menu component
 *
 *  Overrides for dropdown menu bootstrap component
 *
 * ---------------------------------------------------------------------------- */
.dropdown-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  position: relative;
  outline: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  margin-bottom: 0;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .dropdown-item {
      transition: none; } }
  .dropdown-item + .dropdown-item,
  .dropdown-submenu + .dropdown-item,
  .dropdown-item + .dropdown-submenu {
    margin-top: 1px; }
  .dropdown-item:active {
    color: #333;
    background-color: #eee; }
  .dropdown-item.active {
    color: #fff;
    background-color: #2196F3; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #999;
    background-color: transparent;
    cursor: default; }
    .dropdown-item.disabled > .badge,
    .dropdown-item.disabled > img, .dropdown-item:disabled > .badge,
    .dropdown-item:disabled > img {
      opacity: 0.8; }

.dropdown-item-open {
  background-color: #eee;
  color: #333; }

.dropdown-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  line-height: 1.6924; }
  .dropdown-header-highlight {
    background-color: #f8f8f8; }
    .dropdown-item + .dropdown-header-highlight,
    .dropdown-header-highlight + .dropdown-item {
      margin-top: 0.5rem; }

.dropdown-item > i,
.dropdown-item > img,
.dropdown-header > i,
.dropdown-header > img {
  margin-right: 1rem; }

.dropdown-item > i,
.dropdown-header > i {
  top: 0; }

.dropdown-item > img,
.dropdown-header > img {
  max-height: 1.375rem; }

@media (max-width: 575.98px) {
  .dropdown-scrollable-sm {
    max-height: 340px;
    overflow-y: auto; } }

@media (max-width: 768.98px) {
  .dropdown-scrollable-md {
    max-height: 340px;
    overflow-y: auto; } }

@media (max-width: 991.98px) {
  .dropdown-scrollable-lg {
    max-height: 340px;
    overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .dropdown-scrollable-xl {
    max-height: 340px;
    overflow-y: auto; } }

.dropdown-scrollable {
  max-height: 340px;
  overflow-y: auto; }

.dropdown-menu > .dropdown-submenu {
  position: relative; }
  .dropdown-menu > .dropdown-submenu > .dropdown-item {
    padding-right: 2.5rem; }
    .dropdown-menu > .dropdown-submenu > .dropdown-item:after {
      content: "";
      font-family: "icomoon";
      position: absolute;
      top: 50%;
      margin-top: -0.5rem;
      right: 1rem;
      font-size: 1rem;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
      [dir=rtl] .dropdown-menu > .dropdown-submenu > .dropdown-item:after {
        content: ""; }
  .dropdown-menu > .dropdown-submenu.show > .dropdown-item, .dropdown-menu > .dropdown-submenu:hover > .dropdown-item {
    background-color: #eee;
    color: #333; }
  .dropdown-menu > .dropdown-submenu:hover > .dropdown-item:not(.dropdown-toggle) ~ .dropdown-menu,
  .dropdown-menu > .dropdown-submenu:focus > .dropdown-item:not(.dropdown-toggle) ~ .dropdown-menu {
    display: block; }
  .dropdown-menu > .dropdown-submenu.disabled > .dropdown-menu {
    display: none; }
  .dropdown-menu > .dropdown-submenu.disabled > .dropdown-item {
    background-color: transparent;
    color: #999;
    cursor: default; }
  .dropdown-menu > .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -0.5625rem; }
  .dropdown-menu > .dropdown-submenu.dropdown-submenu-left > .dropdown-menu {
    left: auto;
    right: 100%; }
  @media (max-width: 1199.98px) {
    .dropdown-menu > .dropdown-submenu .dropdown-toggle:after {
      content: ""; }
    .dropdown-menu > .dropdown-submenu .dropdown-menu, .dropdown-menu > .dropdown-submenu.dropdown-submenu-left .dropdown-menu {
      position: static;
      margin: 0 !important;
      border-width: 0;
      width: 100%;
      box-shadow: none;
      border-radius: 0; }
    .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-item,
    .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
      padding-left: 2rem; }
    .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-item,
    .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
      padding-left: 3rem; } }

.dropdown-menu .dropup > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -0.5625rem; }

.dropdown-menu .dropdown-submenu + .dropdown-submenu {
  margin-top: 1px; }

.dropright .dropdown-toggle::after {
  vertical-align: middle; }

.dropleft .dropdown-toggle::before {
  vertical-align: middle; }

.dropleft .dropdown-submenu .dropdown-toggle::before {
  content: none; }

[dir=rtl] .navbar-nav > .nav-item:not([class*=mega-menu]) > .dropdown-menu,
[dir=rtl] .nav-item:not([class*=mega-menu]) .navbar-nav-link ~ .dropdown-menu {
  /*rtl:begin:ignore*/
  left: auto;
  right: 0;
  /*rtl:end:ignore*/ }

[dir=rtl] .navbar-nav > .nav-item:not([class*=mega-menu]) > .dropdown-menu-right,
[dir=rtl] .nav-item:not([class*=mega-menu]) .navbar-nav-link ~ .dropdown-menu-right {
  /*rtl:begin:ignore*/
  right: auto;
  left: 0;
  /*rtl:end:ignore*/ }

.dropdown-menu-lg .dropdown-item {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  font-size: 0.9375rem;
  line-height: 1.4667; }

.dropdown-menu-sm .dropdown-item {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-size: 0.8125rem;
  line-height: 1.6924; }

.dropdown-menu-custom > .dropdown-item,
.dropdown-menu-custom > .dropdown-submenu > .dropdown-item {
  color: #fff; }
  .dropdown-menu-custom > .dropdown-item:hover, .dropdown-menu-custom > .dropdown-item:focus,
  .dropdown-menu-custom > .dropdown-submenu > .dropdown-item:hover,
  .dropdown-menu-custom > .dropdown-submenu > .dropdown-item:focus {
    background-color: rgba(255, 255, 255, 0.1); }
  .dropdown-menu-custom > .dropdown-item.active,
  .dropdown-menu-custom > .dropdown-submenu > .dropdown-item.active {
    background-color: rgba(255, 255, 255, 0.2); }
  .dropdown-menu-custom > .dropdown-item.disabled,
  .dropdown-menu-custom > .dropdown-submenu > .dropdown-item.disabled {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.6); }

.dropdown-menu-custom > .dropdown-submenu.show > .dropdown-item, .dropdown-menu-custom > .dropdown-submenu:hover > .dropdown-item {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff; }

.dropdown-menu-custom > .dropdown-submenu.disabled > .dropdown-item {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6); }

.dropdown-menu-custom > .dropdown-header {
  color: rgba(255, 255, 255, 0.6); }
  .dropdown-menu-custom > .dropdown-header-highlight {
    background-color: rgba(0, 0, 0, 0.1); }

.dropdown-menu-custom > .dropdown-divider {
  border-top-color: rgba(255, 255, 255, 0.4); }

/* ------------------------------------------------------------------------------
 *
 *  # Button group component
 *
 *  Overrides for button group bootstrap component
 *
 * ---------------------------------------------------------------------------- */
.btn-group > .btn.border-2:not(:first-child) {
  margin-left: -2px; }

.btn-group > .btn.border-3:not(:first-child) {
  margin-left: -3px; }

.btn-group-justified {
  display: -ms-flexbox;
  display: flex; }
  .btn-group-justified .btn,
  .btn-group-justified .btn-group {
    -ms-flex: 1;
        flex: 1; }

/* ------------------------------------------------------------------------------
 *
 *  # Input group component
 *
 *  Overrides for input group component
 *
 * ---------------------------------------------------------------------------- */
.input-group .form-group-feedback {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  width: 1%;
  z-index: 4; }
  .input-group .form-group-feedback:not(:last-child) .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group .form-group-feedback:not(:first-child) .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.input-group .form-control-feedback,
.input-group + .form-control-feedback,
.form-control-feedback + .input-group {
  z-index: 4; }

.input-group-text i {
  display: block;
  top: 0; }

/* ------------------------------------------------------------------------------
 *
 *  # Custom form inputs
 *
 *  Custom checkboxes, radios, selects and file inputs
 *
 * ---------------------------------------------------------------------------- */
.custom-control {
  padding-left: 0; }
  .custom-control.dropdown-item {
    padding-left: 1rem; }

label.custom-control {
  margin-bottom: 0; }

.custom-control-inline:last-child {
  margin-right: 0; }

.custom-control-input:not(:checked):not(:disabled):active ~ .custom-control-label:before,
.custom-control-input:not(:checked):not(:disabled) ~ .custom-control-label:active:before {
  border-color: #b7b7b7; }

.dropdown-item .custom-control-input {
  left: 1rem; }

.custom-control-input:disabled ~ .custom-control-label {
  cursor: default; }
  .custom-control-input:disabled ~ .custom-control-label:before {
    opacity: .65; }

.custom-control-label {
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-left: 2rem;
  min-width: 1.25rem;
  min-height: 1.25rem; }
  .custom-control-label::before, .custom-control-label::after {
    left: 0;
    top: 0.0625rem; }
  .dropdown-item .custom-control-label {
    padding-left: 2.25rem; }

.custom-control.custom-control-right .custom-control-input {
  left: auto;
  right: 0; }

.custom-control.custom-control-right.dropdown-item .custom-control-input {
  right: 1rem; }

.custom-control.custom-control-right .custom-control-label {
  padding-left: 0;
  padding-right: 2rem; }
  .custom-control.custom-control-right .custom-control-label:before, .custom-control.custom-control-right .custom-control-label:after {
    left: auto;
    right: 0; }

.custom-checkbox-inverse .custom-control-label::before,
.custom-checkbox-inverse .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox-inverse .custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
.custom-checkbox-inverse .custom-control-input:not(:checked):not(:disabled):active ~ .custom-control-label:before,
.custom-checkbox-inverse .custom-control-input:not(:checked):not(:disabled) ~ .custom-control-label:active:before,
.custom-radio-inverse .custom-control-label::before,
.custom-radio-inverse .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio-inverse .custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
.custom-radio-inverse .custom-control-input:not(:checked):not(:disabled):active ~ .custom-control-label:before,
.custom-radio-inverse .custom-control-input:not(:checked):not(:disabled) ~ .custom-control-label:active:before {
  border-color: #fff; }

.custom-checkbox-inverse .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox-inverse .custom-control-input:disabled:checked ~ .custom-control-label::before,
.custom-radio-inverse .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio-inverse .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fff; }

.custom-checkbox-inverse .custom-control-label::before,
.custom-checkbox-inverse .custom-control-input:disabled ~ .custom-control-label::before,
.custom-radio-inverse .custom-control-label::before,
.custom-radio-inverse .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: transparent; }

.custom-checkbox-inverse .custom-control-input:disabled ~ .custom-control-label,
.custom-radio-inverse .custom-control-input:disabled ~ .custom-control-label {
  color: rgba(255, 255, 255, 0.75); }

.custom-checkbox-inverse .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='%23000' d='M13.5 2l-7.5 7.5-3.5-3.5-2.5 2.5 6 6 10-10z'/%3e%3c/svg%3e"); }

.custom-radio-inverse .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e"); }

.custom-switch .custom-control-label {
  display: inline-block;
  min-width: 2.1875rem;
  padding-left: 2.9375rem; }
  .custom-switch .custom-control-label::before {
    border: 0;
    left: 0;
    background-color: #999; }
  .custom-switch .custom-control-label::after {
    top: calc(0.0625rem + 2px);
    left: 2px;
    background-color: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.25); }
  .dropdown-item .custom-switch .custom-control-label {
    padding-left: 3.1875rem; }

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff; }

.custom-switch .custom-control-input:checked ~ .custom-control-label::before,
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #2196F3; }

.custom-switch .custom-control-input:not(:checked):active ~ .custom-control-label::before,
.custom-switch .custom-control-input:disabled ~ .custom-control-label::before,
.custom-switch .custom-control-input[disabled] ~ .custom-control-label::before {
  background-color: #999; }

.custom-switch .custom-control-input:disabled ~ .custom-control-label:after {
  opacity: 0.9; }

.custom-switch.custom-control-right .custom-control-label {
  padding-right: 2.9375rem; }
  .custom-switch.custom-control-right .custom-control-label:after {
    right: calc(1.1875rem - 2px); }

.custom-switch-square .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-switch-square .custom-control-label::after {
  border-radius: calc(0.25rem - 1px); }

.custom-switch-inverse .custom-control-input:checked ~ .custom-control-label::before,
.custom-switch-inverse .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.5); }

.custom-switch-inverse .custom-control-label::before,
.custom-switch-inverse .custom-control-input:not(:checked):active ~ .custom-control-label::before,
.custom-switch-inverse .custom-control-input:disabled ~ .custom-control-label::before,
.custom-switch-inverse .custom-control-input[disabled] ~ .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.5); }

.custom-control-secondary .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-secondary .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #45748a;
  border-color: #45748a; }

.custom-control-dark .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-dark .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #252b36;
  border-color: #252b36; }

.custom-control-success .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-success .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #25b372;
  border-color: #25b372; }

.custom-control-danger .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-danger .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #ef5350;
  border-color: #ef5350; }

.custom-control-warning .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-warning .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #f58646;
  border-color: #f58646; }

.custom-control-info .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-info .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #2cbacc;
  border-color: #2cbacc; }

.custom-select {
  cursor: pointer; }
  .custom-select:disabled {
    cursor: default; }

.custom-file-label {
  margin-bottom: 0; }
  .custom-file-label:after {
    border-top-right-radius: calc(0.25rem - 1px);
    border-bottom-right-radius: calc(0.25rem - 1px); }
  .custom-file-label.border-transparent:after {
    border-left-color: #ddd; }

/* ------------------------------------------------------------------------------
 *
 *  # Nav components
 *
 *  Overrides for nav components
 *
 * ---------------------------------------------------------------------------- */
.nav-link {
  position: relative;
  transition: all ease-in-out 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:focus {
    outline: 0; }
  .nav-link::-moz-focus-inner {
    border: 0; }
  .nav-link.disabled {
    cursor: default; }

.nav-tabs {
  margin-bottom: 1.25rem; }
  .nav-tabs .nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: #777; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      color: #333; }
    .nav-tabs .nav-link.disabled {
      color: #999; }
  .nav-tabs .nav-item.show .nav-link:not(.active) {
    border-color: transparent;
    background-color: transparent; }
  .nav-tabs.border-top-1 {
    border-top-color: #ddd; }

.nav-tabs-responsive {
  overflow-x: auto;
  box-shadow: 0 -1px 0 #ddd inset; }
  .nav-tabs-responsive .nav-tabs {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    white-space: nowrap;
    margin-bottom: 0; }

.nav-tabs-highlight .nav-link,
.nav-tabs-top .nav-link,
.nav-tabs-bottom .nav-link {
  position: relative; }
  .nav-tabs-highlight .nav-link:before,
  .nav-tabs-top .nav-link:before,
  .nav-tabs-bottom .nav-link:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: background-color ease-in-out 0.15s; }
    @media (prefers-reduced-motion: reduce) {
      .nav-tabs-highlight .nav-link:before,
      .nav-tabs-top .nav-link:before,
      .nav-tabs-bottom .nav-link:before {
        transition: none; } }

.nav-tabs-highlight .nav-link {
  border-top-color: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .nav-tabs-highlight .nav-link:before {
    height: 2px;
    top: -1px;
    left: -1px;
    right: -1px; }

.nav-tabs-highlight .nav-link.active:before {
  background-color: #2196F3; }

.nav-tabs-top .nav-item {
  margin-bottom: 0; }

.nav-tabs-top .nav-link {
  border-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .nav-tabs-top .nav-link:hover:before, .nav-tabs-top .nav-link:focus:before {
    background-color: #ddd; }
  .nav-tabs-top .nav-link:before {
    height: 2px; }
  .nav-tabs-top .nav-link.disabled:before {
    content: none; }

.nav-tabs-top .nav-item.show .nav-link:not(.active):before {
  background-color: #ddd; }

.nav-tabs-top .nav-link.active:before {
  background-color: #f35c86; }

.nav-tabs-top .nav-link.active,
.nav-tabs-top .nav-item.show .nav-link {
  background-color: transparent; }

.nav-tabs-top .dropdown-menu {
  margin-top: 0; }

.nav-tabs-top.border-top-1 .nav-link {
  margin-top: -1px; }

.nav-tabs-bottom .nav-link {
  border-width: 0; }
  .nav-tabs-bottom .nav-link:before {
    height: 2px;
    top: auto;
    bottom: 0; }

.nav-tabs-bottom .nav-link.active:before {
  background-color: #f35c86; }

.nav-tabs-bottom .nav-link.active,
.nav-tabs-bottom .nav-item.show .nav-link {
  background-color: transparent; }

.nav-tabs-bottom .dropdown-menu {
  margin-top: 0; }

.nav-tabs-solid {
  background-color: #f5f5f5;
  border-bottom: 0; }
  .nav-tabs-solid .nav-link {
    border-width: 0;
    margin-bottom: 0; }
  .nav-tabs-solid .nav-item.show .nav-link {
    background-color: transparent; }
  .nav-tabs-solid .nav-link.active,
  .nav-tabs-solid .nav-item.show .nav-link.active {
    color: #fff;
    background-color: #2196F3; }
  .nav-tabs-solid.border .nav-link.active,
  .nav-tabs-solid.border .nav-item.show .nav-link.active {
    box-shadow: 0 0 0 1px #2196F3; }
  .nav-tabs-solid.rounded-top .nav-item:first-child > .nav-link {
    border-top-left-radius: 0.25rem; }
  .nav-tabs-solid.rounded-top .nav-item:last-child > .nav-link {
    border-top-right-radius: 0.25rem; }
  .nav-tabs-solid.rounded-bottom .nav-item:first-child > .nav-link {
    border-bottom-left-radius: 0.25rem; }
  .nav-tabs-solid.rounded-bottom .nav-item:last-child > .nav-link {
    border-bottom-right-radius: 0.25rem; }
  .nav-tabs-solid-custom .nav-link {
    color: rgba(255, 255, 255, 0.85); }
    .nav-tabs-solid-custom .nav-link:hover, .nav-tabs-solid-custom .nav-link:focus {
      color: #fff; }
    .nav-tabs-solid-custom .nav-link.disabled {
      color: rgba(255, 255, 255, 0.6); }
  .nav-tabs-solid-custom .nav-item.show .nav-link {
    color: #fff; }
  .nav-tabs-solid-custom .nav-link.active,
  .nav-tabs-solid-custom .nav-item.show .nav-link.active {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.15); }
  .nav-tabs-solid-custom + .tab-content-dark {
    border-top-color: rgba(255, 255, 255, 0.5); }

.nav-tabs-vertical .nav-link {
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .nav-tabs-vertical .nav-link, .nav-tabs-vertical .nav-link:hover, .nav-tabs-vertical .nav-link:focus, .nav-tabs-vertical .nav-link.disabled {
    border-color: transparent #ddd transparent transparent; }
  .nav-tabs-vertical .nav-link:before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: 2px;
    transition: background-color ease-in-out 0.15s; }
    @media (prefers-reduced-motion: reduce) {
      .nav-tabs-vertical .nav-link:before {
        transition: none; } }

.nav-tabs-vertical .nav-item.show .nav-link:not(.active) {
  border-right-color: #ddd;
  border-bottom-color: transparent; }

.nav-tabs-vertical-right .nav-link, .nav-tabs-vertical-right .nav-link:hover, .nav-tabs-vertical-right .nav-link:focus, .nav-tabs-vertical-right .nav-link.disabled {
  border-color: transparent transparent transparent #ddd; }

.nav-tabs-vertical-right .nav-link:before {
  left: auto;
  right: -1px; }

.nav-tabs-vertical-right .nav-item.show .nav-link:not(.active) {
  border-right-color: transparent;
  border-left-color: #ddd; }

.nav-tabs-vertical .nav-link.active:before {
  background-color: #2196F3; }

.nav-tabs-vertical .nav-link.active,
.nav-tabs-vertical .nav-item.show .nav-link {
  background-color: transparent;
  border-color: #ddd transparent; }

.nav-pills {
  margin-bottom: 1.25rem; }
  .nav-pills .nav-link {
    color: #777; }
    .nav-pills .nav-link:not(.active):hover, .nav-pills .nav-link:not(.active):focus {
      color: #333; }
    .nav-pills .nav-link.disabled, .nav-pills .nav-link.disabled:hover, .nav-pills .nav-link.disabled:focus {
      color: #999; }
  .nav-pills .nav-item.show .nav-link:not(.active) {
    color: #333;
    background-color: transparent; }
  .nav-pills.flex-column .nav-link {
    -ms-flex-pack: start;
        justify-content: flex-start; }

.nav-pills-bordered .nav-item + .nav-item {
  margin-left: 0.625rem; }

.nav-pills-bordered .nav-link {
  border: 1px solid #ddd; }

.nav-pills-bordered .nav-link:not(.active):hover,
.nav-pills-bordered .nav-link:not(.active):focus,
.nav-pills-bordered .nav-item.show .nav-link:not(.active) {
  background-color: #f5f5f5; }

.nav-pills-bordered .nav-link.active {
  border-color: #2196F3; }

.nav-pills-bordered .nav-link.disabled, .nav-pills-bordered .nav-link.disabled:hover, .nav-pills-bordered .nav-link.disabled:focus {
  background-color: #fafafa; }

.nav-pills-bordered.flex-column .nav-item + .nav-item {
  margin-left: 0;
  margin-top: 0.625rem; }

.nav-pills-toolbar .nav-item + .nav-item {
  margin-left: -1px; }

.nav-pills-toolbar .nav-link {
  border-radius: 0; }
  .nav-pills-toolbar .nav-link.active {
    z-index: 3; }

.nav-pills-toolbar .nav-item:first-child .nav-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.nav-pills-toolbar .nav-item:last-child .nav-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.nav-pills-toolbar.flex-column .nav-item:first-child .nav-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.nav-pills-toolbar.flex-column .nav-item:last-child .nav-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.nav-pills-toolbar.flex-column .nav-item + .nav-item {
  margin-left: 0;
  margin-top: -1px; }

/* ------------------------------------------------------------------------------
 *
 *  # Navbar component
 *
 *  Overrides for navbar component
 *
 * ---------------------------------------------------------------------------- */
.navbar {
  border-bottom: 1px solid transparent;
  -ms-flex-align: stretch;
      align-items: stretch;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-negative: 0;
      flex-shrink: 0; }

.navbar-brand {
  margin: 0; }
  .navbar-brand img {
    height: 1.125rem; }

.navbar-text {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.navbar-search {
  position: relative;
  width: 100%; }
  .navbar-search .form-control {
    border: 0;
    border-radius: 50rem;
    transition: background-color ease-in-out 0.15s; }
  .navbar-dark .navbar-search .form-control {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.25); }
    .navbar-dark .navbar-search .form-control:focus {
      background-color: rgba(0, 0, 0, 0.3); }
    .navbar-dark .navbar-search .form-control::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.5); }
    .navbar-dark .navbar-search .form-control::-moz-placeholder {
      color: rgba(255, 255, 255, 0.5); }
    .navbar-dark .navbar-search .form-control:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.5); }
    .navbar-dark .navbar-search .form-control::-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.5); }
    .navbar-dark .navbar-search .form-control::placeholder {
      color: rgba(255, 255, 255, 0.5); }
  .navbar-light .navbar-search .form-control {
    background-color: #eee; }
    .navbar-light .navbar-search .form-control:focus {
      background-color: #ddd; }

.navbar-nav .nav-link {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.navbar-nav-link {
  position: relative;
  display: block;
  cursor: pointer;
  padding: 1rem 1.25rem;
  outline: 0;
  transition: color ease-in-out 0.15s, background-color ease-in-out 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-nav-link {
      transition: none; } }
  .navbar-nav-link.disabled {
    cursor: default; }
    .navbar-nav-link.disabled .badge,
    .navbar-nav-link.disabled img {
      opacity: 0.5; }
  .navbar-nav-link > .badge {
    position: absolute;
    top: 0;
    right: 0; }
    .navbar-nav-link > .badge-mark {
      top: 0.5rem;
      right: 0.625rem; }

.navbar-nav-underline .navbar-nav-link, .navbar-nav-underline .navbar-nav-link:hover, .navbar-nav-underline .navbar-nav-link:focus, .navbar-nav-underline .navbar-nav-link.active {
  background-color: transparent !important; }

.navbar-nav-underline .navbar-nav-link:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  height: 2px; }

.navbar-light .navbar-nav-underline .navbar-nav-link.active:before {
  background-color: #f57d9e; }

.navbar-dark .navbar-nav-underline .navbar-nav-link.active:before {
  background-color: #fff; }

.navbar-toggler {
  position: relative;
  border: 0;
  line-height: 1.5715;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  cursor: pointer;
  transition: color ease-in-out 0.15s, background-color ease-in-out 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover, .navbar-toggler:focus {
    outline: 0; }
  .navbar-toggler .badge {
    position: absolute;
    top: 0;
    right: -0.3125rem; }
  .navbar-dark .navbar-toggler:hover, .navbar-dark .navbar-toggler:focus {
    color: #fff; }
  .navbar-light .navbar-toggler {
    color: #333; }
    .navbar-light .navbar-toggler:hover, .navbar-light .navbar-toggler:focus, .navbar-light .navbar-toggler[aria-expanded=true] {
      color: #333; }

@media (max-width: 575.98px) {
  .navbar-expand-sm:not(.navbar-expand) {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .navbar-expand-sm .navbar-nav-link-toggler {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
    .navbar-expand-sm .navbar-nav-link-toggler .badge-mark {
      right: 0.25rem; }
    .navbar-expand-sm .navbar-nav-link-toggler.dropdown-toggle:after {
      content: none; }
  .navbar-expand-sm .navbar-collapse {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-left: -0.625rem;
    margin-right: -0.625rem; }
    .navbar-expand-sm .navbar-collapse .navbar-nav:first-of-type > .nav-item:first-child {
      margin-top: 0.625rem; }
    .navbar-expand-sm .navbar-collapse .navbar-nav:last-of-type > .nav-item:not(.show):last-child {
      margin-bottom: 0.625rem; }
    .navbar-expand-sm .navbar-collapse .navbar-nav-link {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      padding: 0.75rem 1.25rem; }
      .navbar-expand-sm .navbar-collapse .navbar-nav-link > .badge:not(.position-static) {
        position: static; }
      .navbar-expand-sm .navbar-collapse .navbar-nav-link.dropdown-toggle {
        padding-right: 2.5rem; }
        .navbar-expand-sm .navbar-collapse .navbar-nav-link.dropdown-toggle:after {
          position: absolute;
          top: 50%;
          right: 1.25rem;
          margin: 0;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%); }
    .navbar-expand-sm .navbar-collapse .navbar-nav > .nav-item > .dropdown-menu {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem; }
    .navbar-expand-sm .navbar-collapse .navbar-nav-underline .navbar-nav-link:before {
      right: auto;
      top: 0;
      height: auto;
      width: 2px; }
    .navbar-expand-sm .navbar-collapse .dropdown-submenu .dropdown-toggle:after {
      content: ""; }
    .navbar-expand-sm .navbar-collapse .dropdown-submenu .dropdown-menu, .navbar-expand-sm .navbar-collapse .dropdown-submenu.dropdown-submenu-left .dropdown-menu {
      margin: 0;
      border-width: 0;
      box-shadow: none;
      border-radius: 0; }
    .navbar-expand-sm .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-item,
    .navbar-expand-sm .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
      padding-left: 2rem; }
    .navbar-expand-sm .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-item,
    .navbar-expand-sm .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
      padding-left: 3rem; }
  .navbar-expand-sm.navbar-dark .navbar-collapse {
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .navbar-expand-sm.navbar-light .navbar-collapse {
    border-top: 1px solid #ddd; } }

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-brand {
    min-width: 17.5rem; }
  .navbar-expand-sm .navbar-search {
    max-width: 30rem; } }

@media (max-width: 768.98px) {
  .navbar-expand-md:not(.navbar-expand) {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .navbar-expand-md .navbar-nav-link-toggler {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
    .navbar-expand-md .navbar-nav-link-toggler .badge-mark {
      right: 0.25rem; }
    .navbar-expand-md .navbar-nav-link-toggler.dropdown-toggle:after {
      content: none; }
  .navbar-expand-md .navbar-collapse {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-left: -0.625rem;
    margin-right: -0.625rem; }
    .navbar-expand-md .navbar-collapse .navbar-nav:first-of-type > .nav-item:first-child {
      margin-top: 0.625rem; }
    .navbar-expand-md .navbar-collapse .navbar-nav:last-of-type > .nav-item:not(.show):last-child {
      margin-bottom: 0.625rem; }
    .navbar-expand-md .navbar-collapse .navbar-nav-link {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      padding: 0.75rem 1.25rem; }
      .navbar-expand-md .navbar-collapse .navbar-nav-link > .badge:not(.position-static) {
        position: static; }
      .navbar-expand-md .navbar-collapse .navbar-nav-link.dropdown-toggle {
        padding-right: 2.5rem; }
        .navbar-expand-md .navbar-collapse .navbar-nav-link.dropdown-toggle:after {
          position: absolute;
          top: 50%;
          right: 1.25rem;
          margin: 0;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%); }
    .navbar-expand-md .navbar-collapse .navbar-nav > .nav-item > .dropdown-menu {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem; }
    .navbar-expand-md .navbar-collapse .navbar-nav-underline .navbar-nav-link:before {
      right: auto;
      top: 0;
      height: auto;
      width: 2px; }
    .navbar-expand-md .navbar-collapse .dropdown-submenu .dropdown-toggle:after {
      content: ""; }
    .navbar-expand-md .navbar-collapse .dropdown-submenu .dropdown-menu, .navbar-expand-md .navbar-collapse .dropdown-submenu.dropdown-submenu-left .dropdown-menu {
      margin: 0;
      border-width: 0;
      box-shadow: none;
      border-radius: 0; }
    .navbar-expand-md .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-item,
    .navbar-expand-md .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
      padding-left: 2rem; }
    .navbar-expand-md .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-item,
    .navbar-expand-md .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
      padding-left: 3rem; }
  .navbar-expand-md.navbar-dark .navbar-collapse {
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .navbar-expand-md.navbar-light .navbar-collapse {
    border-top: 1px solid #ddd; } }

@media (min-width: 769px) {
  .navbar-expand-md .navbar-brand {
    min-width: 17.5rem; }
  .navbar-expand-md .navbar-search {
    max-width: 30rem; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg:not(.navbar-expand) {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .navbar-expand-lg .navbar-nav-link-toggler {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
    .navbar-expand-lg .navbar-nav-link-toggler .badge-mark {
      right: 0.25rem; }
    .navbar-expand-lg .navbar-nav-link-toggler.dropdown-toggle:after {
      content: none; }
  .navbar-expand-lg .navbar-collapse {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-left: -0.625rem;
    margin-right: -0.625rem; }
    .navbar-expand-lg .navbar-collapse .navbar-nav:first-of-type > .nav-item:first-child {
      margin-top: 0.625rem; }
    .navbar-expand-lg .navbar-collapse .navbar-nav:last-of-type > .nav-item:not(.show):last-child {
      margin-bottom: 0.625rem; }
    .navbar-expand-lg .navbar-collapse .navbar-nav-link {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      padding: 0.75rem 1.25rem; }
      .navbar-expand-lg .navbar-collapse .navbar-nav-link > .badge:not(.position-static) {
        position: static; }
      .navbar-expand-lg .navbar-collapse .navbar-nav-link.dropdown-toggle {
        padding-right: 2.5rem; }
        .navbar-expand-lg .navbar-collapse .navbar-nav-link.dropdown-toggle:after {
          position: absolute;
          top: 50%;
          right: 1.25rem;
          margin: 0;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%); }
    .navbar-expand-lg .navbar-collapse .navbar-nav > .nav-item > .dropdown-menu {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem; }
    .navbar-expand-lg .navbar-collapse .navbar-nav-underline .navbar-nav-link:before {
      right: auto;
      top: 0;
      height: auto;
      width: 2px; }
    .navbar-expand-lg .navbar-collapse .dropdown-submenu .dropdown-toggle:after {
      content: ""; }
    .navbar-expand-lg .navbar-collapse .dropdown-submenu .dropdown-menu, .navbar-expand-lg .navbar-collapse .dropdown-submenu.dropdown-submenu-left .dropdown-menu {
      margin: 0;
      border-width: 0;
      box-shadow: none;
      border-radius: 0; }
    .navbar-expand-lg .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-item,
    .navbar-expand-lg .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
      padding-left: 2rem; }
    .navbar-expand-lg .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-item,
    .navbar-expand-lg .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
      padding-left: 3rem; }
  .navbar-expand-lg.navbar-dark .navbar-collapse {
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .navbar-expand-lg.navbar-light .navbar-collapse {
    border-top: 1px solid #ddd; } }

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-brand {
    min-width: 17.5rem; }
  .navbar-expand-lg .navbar-search {
    max-width: 30rem; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl:not(.navbar-expand) {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .navbar-expand-xl .navbar-nav-link-toggler {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
    .navbar-expand-xl .navbar-nav-link-toggler .badge-mark {
      right: 0.25rem; }
    .navbar-expand-xl .navbar-nav-link-toggler.dropdown-toggle:after {
      content: none; }
  .navbar-expand-xl .navbar-collapse {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-left: -0.625rem;
    margin-right: -0.625rem; }
    .navbar-expand-xl .navbar-collapse .navbar-nav:first-of-type > .nav-item:first-child {
      margin-top: 0.625rem; }
    .navbar-expand-xl .navbar-collapse .navbar-nav:last-of-type > .nav-item:not(.show):last-child {
      margin-bottom: 0.625rem; }
    .navbar-expand-xl .navbar-collapse .navbar-nav-link {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      padding: 0.75rem 1.25rem; }
      .navbar-expand-xl .navbar-collapse .navbar-nav-link > .badge:not(.position-static) {
        position: static; }
      .navbar-expand-xl .navbar-collapse .navbar-nav-link.dropdown-toggle {
        padding-right: 2.5rem; }
        .navbar-expand-xl .navbar-collapse .navbar-nav-link.dropdown-toggle:after {
          position: absolute;
          top: 50%;
          right: 1.25rem;
          margin: 0;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%); }
    .navbar-expand-xl .navbar-collapse .navbar-nav > .nav-item > .dropdown-menu {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem; }
    .navbar-expand-xl .navbar-collapse .navbar-nav-underline .navbar-nav-link:before {
      right: auto;
      top: 0;
      height: auto;
      width: 2px; }
    .navbar-expand-xl .navbar-collapse .dropdown-submenu .dropdown-toggle:after {
      content: ""; }
    .navbar-expand-xl .navbar-collapse .dropdown-submenu .dropdown-menu, .navbar-expand-xl .navbar-collapse .dropdown-submenu.dropdown-submenu-left .dropdown-menu {
      margin: 0;
      border-width: 0;
      box-shadow: none;
      border-radius: 0; }
    .navbar-expand-xl .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-item,
    .navbar-expand-xl .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
      padding-left: 2rem; }
    .navbar-expand-xl .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-item,
    .navbar-expand-xl .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
      padding-left: 3rem; }
  .navbar-expand-xl.navbar-dark .navbar-collapse {
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .navbar-expand-xl.navbar-light .navbar-collapse {
    border-top: 1px solid #ddd; } }

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-brand {
    min-width: 17.5rem; }
  .navbar-expand-xl .navbar-search {
    max-width: 30rem; } }

.navbar-expand:not(.navbar-expand) {
  padding-left: 0.625rem;
  padding-right: 0.625rem; }

.navbar-expand .navbar-nav-link-toggler {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }
  .navbar-expand .navbar-nav-link-toggler .badge-mark {
    right: 0.25rem; }
  .navbar-expand .navbar-nav-link-toggler.dropdown-toggle:after {
    content: none; }

.navbar-expand .navbar-collapse {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  .navbar-expand .navbar-collapse .navbar-nav:first-of-type > .nav-item:first-child {
    margin-top: 0.625rem; }
  .navbar-expand .navbar-collapse .navbar-nav:last-of-type > .nav-item:not(.show):last-child {
    margin-bottom: 0.625rem; }
  .navbar-expand .navbar-collapse .navbar-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    padding: 0.75rem 1.25rem; }
    .navbar-expand .navbar-collapse .navbar-nav-link > .badge:not(.position-static) {
      position: static; }
    .navbar-expand .navbar-collapse .navbar-nav-link.dropdown-toggle {
      padding-right: 2.5rem; }
      .navbar-expand .navbar-collapse .navbar-nav-link.dropdown-toggle:after {
        position: absolute;
        top: 50%;
        right: 1.25rem;
        margin: 0;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
  .navbar-expand .navbar-collapse .navbar-nav > .nav-item > .dropdown-menu {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .navbar-expand .navbar-collapse .navbar-nav-underline .navbar-nav-link:before {
    right: auto;
    top: 0;
    height: auto;
    width: 2px; }
  .navbar-expand .navbar-collapse .dropdown-submenu .dropdown-toggle:after {
    content: ""; }
  .navbar-expand .navbar-collapse .dropdown-submenu .dropdown-menu, .navbar-expand .navbar-collapse .dropdown-submenu.dropdown-submenu-left .dropdown-menu {
    margin: 0;
    border-width: 0;
    box-shadow: none;
    border-radius: 0; }
  .navbar-expand .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-item,
  .navbar-expand .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
    padding-left: 2rem; }
  .navbar-expand .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-item,
  .navbar-expand .navbar-collapse .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
    padding-left: 3rem; }

.navbar-expand.navbar-dark .navbar-collapse {
  border-top: 1px solid rgba(255, 255, 255, 0.1); }

.navbar-expand.navbar-light .navbar-collapse {
  border-top: 1px solid #ddd; }

.navbar-expand .navbar-brand {
  min-width: 17.5rem; }

.navbar-expand .navbar-search {
  max-width: 30rem; }

@media (max-width: 575.98px) {
  .nav-item-dropdown-sm {
    position: static; }
    .nav-item-dropdown-sm > .dropdown-menu {
      position: absolute;
      left: 0.625rem !important;
      right: 0.625rem !important; } }

@media (max-width: 768.98px) {
  .nav-item-dropdown-md {
    position: static; }
    .nav-item-dropdown-md > .dropdown-menu {
      position: absolute;
      left: 0.625rem !important;
      right: 0.625rem !important; } }

@media (max-width: 991.98px) {
  .nav-item-dropdown-lg {
    position: static; }
    .nav-item-dropdown-lg > .dropdown-menu {
      position: absolute;
      left: 0.625rem !important;
      right: 0.625rem !important; } }

@media (max-width: 1199.98px) {
  .nav-item-dropdown-xl {
    position: static; }
    .nav-item-dropdown-xl > .dropdown-menu {
      position: absolute;
      left: 0.625rem !important;
      right: 0.625rem !important; } }

.nav-item-dropdown {
  position: static; }
  .nav-item-dropdown > .dropdown-menu {
    position: absolute;
    left: 0.625rem !important;
    right: 0.625rem !important; }

.navbar-light {
  color: #333;
  background-color: #fff;
  border-bottom-color: rgba(0, 0, 0, 0.125); }
  .navbar-light .navbar-nav .nav-link {
    color: #333; }
    .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
      color: #333; }
    .navbar-light .navbar-nav .nav-link.disabled {
      color: #999; }
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    color: #333; }
  .navbar-light .navbar-nav-link {
    color: #333; }
    .navbar-light .navbar-nav-link:hover, .navbar-light .navbar-nav-link:focus {
      color: #333;
      background-color: rgba(0, 0, 0, 0.04); }
    .navbar-light .navbar-nav-link.disabled {
      color: rgba(0, 0, 0, 0.65);
      background-color: transparent; }
  .navbar-light .show > .navbar-nav-link,
  .navbar-light .active > .navbar-nav-link,
  .navbar-light .navbar-nav-link.show,
  .navbar-light .navbar-nav-link.active {
    color: #333;
    background-color: rgba(0, 0, 0, 0.04); }
  .navbar-light .navbar-text a {
    color: #2196F3; }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: #0a6ebd; }

.navbar-dark {
  color: #fff;
  background-color: #252b36;
  border-bottom-color: rgba(255, 255, 255, 0.1); }
  .navbar-dark .navbar-nav .nav-link {
    color: #333; }
    .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
      color: #333; }
    .navbar-dark .navbar-nav .nav-link.disabled {
      color: #999; }
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #333; }
  .navbar-dark .navbar-nav-link {
    color: #fff; }
    .navbar-dark .navbar-nav-link:hover, .navbar-dark .navbar-nav-link:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.1); }
    .navbar-dark .navbar-nav-link.disabled {
      color: rgba(255, 255, 255, 0.65);
      background-color: transparent; }
  .navbar-dark .show > .navbar-nav-link,
  .navbar-dark .active > .navbar-nav-link,
  .navbar-dark .navbar-nav-link.show,
  .navbar-dark .navbar-nav-link.active {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1); }

.navbar-nav .nav-tabs .nav-link {
  color: #777; }
  .navbar-nav .nav-tabs .nav-link:hover, .navbar-nav .nav-tabs .nav-link:focus {
    color: #333; }
  .navbar-nav .nav-tabs .nav-link.disabled {
    color: #999; }

.navbar-nav .nav-tabs .show > .nav-link,
.navbar-nav .nav-tabs .active > .nav-link,
.navbar-nav .nav-tabs .nav-link.show,
.navbar-nav .nav-tabs .nav-link.active {
  color: #333; }

.navbar-nav .nav-pills .nav-link {
  color: #777; }
  .navbar-nav .nav-pills .nav-link:not(.active):hover, .navbar-nav .nav-pills .nav-link:not(.active):focus {
    color: #333; }
  .navbar-nav .nav-pills .nav-link.active,
  .show:not(.navbar-collapse) > .navbar-nav .nav-pills .nav-link {
    color: #fff; }
  .navbar-nav .nav-pills .nav-link.disabled:hover, .navbar-nav .nav-pills .nav-link.disabled:focus {
    color: #999; }

.navbar .btn-group .dropdown-menu-right {
  /*rtl:begin:ignore*/
  right: 0;
  left: auto;
  /*rtl:end:ignore*/ }

.dropdown-user .navbar-nav-link,
.dropdown-user .navbar-nav-link > span {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.img-flag {
  height: 0.9375rem;
  margin-top: 0.21875rem;
  vertical-align: top;
  -ms-flex-item-align: start;
      align-self: flex-start; }

.mega-menu-left > .dropdown-menu {
  /*rtl:ignore*/
  left: auto; }

.mega-menu-right > .dropdown-menu {
  left: auto;
  right: 0; }

.mega-menu-full > .dropdown-menu {
  left: 1.25rem;
  right: 1.25rem; }
  .navbar-component .mega-menu-full > .dropdown-menu {
    left: 0;
    right: 0; }

.dropdown-content-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem; }
  .dropdown-content-header:not([class*=bg-]) {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .dropdown-content-header[class*=bg-] {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .dropdown-content-header + .table-responsive,
  .dropdown-content-header + .table {
    border-top: 1px solid #ddd; }

.dropdown-content:not(ul) {
  padding-top: 0;
  padding-bottom: 0; }

.dropdown-content-body {
  padding: 1.25rem 1.25rem; }
  .dropdown-content-header:not([class*=bg-]) + .dropdown-content-body {
    padding-top: 0; }

.dropdown-content-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.75rem 1.25rem;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem; }

.navbar-top {
  padding-top: calc(3.375rem + 1px); }

.navbar-lg-top {
  padding-top: calc(3.875rem + 1px); }

.navbar-sm-top {
  padding-top: calc(2.875rem + 1px); }

.navbar-lg-lg-top {
  padding-top: calc(7.75rem + 2px); }

.navbar-lg-md-top {
  padding-top: calc(7.25rem + 2px); }

.navbar-md-md-top {
  padding-top: calc(6.75rem + 2px); }

.navbar-md-sm-top {
  padding-top: calc(6.25rem + 2px); }

.navbar-bottom {
  padding-bottom: calc(3.375rem + 1px); }

.navbar-lg-bottom {
  padding-bottom: calc(3.875rem + 1px); }

.navbar-sm-bottom {
  padding-bottom: calc(2.875rem + 1px); }

.navbar-lg-lg-bottom {
  padding-bottom: calc(7.75rem + 2px); }

.navbar-lg-md-bottom {
  padding-bottom: calc(7.25rem + 2px); }

.navbar-md-md-bottom {
  padding-bottom: calc(6.75rem + 2px); }

.navbar-md-sm-bottom {
  padding-bottom: calc(6.25rem + 2px); }

.navbar-lg .navbar-brand {
  padding-top: 1.375rem;
  padding-bottom: 1.375rem; }

.navbar-lg .navbar-toggler {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.navbar-lg .navbar-nav-link {
  padding: 1.25rem 1.5rem; }
  .navbar-lg .navbar-nav-link .badge-mark {
    top: 0.625rem;
    right: 0.75rem; }

.navbar-lg .navbar-text {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.navbar-lg .dropdown-user > .navbar-nav-link,
.navbar-lg .dropdown-user > .navbar-nav-link > span {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }

.navbar-lg .dropdown-user > .navbar-nav-link > img {
  max-height: 2.62506rem; }

.navbar-sm .navbar-brand {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem; }

.navbar-sm .navbar-toggler {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.navbar-sm .navbar-nav-link {
  padding: 0.75rem 0.875rem; }
  .navbar-sm .navbar-nav-link .badge-mark {
    top: 0.375rem;
    right: 0.4375rem; }

.navbar-sm .navbar-text {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.navbar-sm .dropdown-user > .navbar-nav-link,
.navbar-sm .dropdown-user > .navbar-nav-link > span {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem; }

.navbar-sm .dropdown-user > .navbar-nav-link > img {
  max-height: 2.12506rem; }

.navbar-static {
  z-index: 1024; }
  .navbar-static + .navbar-static {
    z-index: 1023; }
  .content-wrapper .navbar-static {
    z-index: 1021; }

/* ------------------------------------------------------------------------------
 *
 *  # Card component
 *
 *  Overrides for card bootstrap component
 *
 * ---------------------------------------------------------------------------- */
.card {
  margin-bottom: 1.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
  .card.fixed-top {
    overflow: auto;
    max-height: 100%; }

.card-body:not(.card) + .card-body:not(.card) {
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-title {
  position: relative;
  margin-bottom: 1rem; }
  .card-header .card-title {
    margin-bottom: 0; }

.card-header[class*=bg-] {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .card-header {
  border-bottom-color: rgba(255, 255, 255, 0.1); }

.card-header:not([class*=bg-]) {
  background-color: transparent;
  border-bottom-width: 0; }
  .card-header:not([class*=bg-]) + .card-body,
  .card-header:not([class*=bg-]) + * > .card-body:first-child {
    padding-top: 0; }

.card-footer {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .card-footer:first-child,
  .card-header + .card-footer,
  .card-header + * > .card-footer:first-child {
    border-top: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer.border-bottom-1 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-header-tabs {
  margin-bottom: 0; }
  .card-header-tabs .nav-link {
    border-top-width: 0; }

.card-body .card-img {
  border-radius: 0.25rem; }

.card-img-actions {
  position: relative; }
  .card-img-actions:hover .card-img-actions-overlay, .card-img-actions:focus .card-img-actions-overlay {
    opacity: 1;
    visibility: visible; }

.card-img-actions-overlay {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .card-img-actions-overlay {
      transition: none; } }

[data-action] {
  cursor: pointer; }
  [data-action]:after {
    font-family: "icomoon";
    font-size: 1rem;
    min-width: 1rem;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

[data-action="collapse"]:after {
  content: "";
  transition: -webkit-transform ease-in-out 0.15s;
  transition: transform ease-in-out 0.15s;
  transition: transform ease-in-out 0.15s, -webkit-transform ease-in-out 0.15s; }
  .card-collapsed [data-action="collapse"]:after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

[data-action="reload"]:after {
  content: ""; }

[data-action="remove"]:after {
  content: "";
  font-size: 0.875rem; }

[data-action="move"] {
  cursor: move; }
  [data-action="move"]:after {
    content: ""; }

[data-action="fullscreen"]:after {
  content: ""; }

[data-action="fullscreen"][data-fullscreen="active"]:after {
  content: ""; }

[data-action="modal"]:after {
  content: ""; }

.card-group-control .card-title > a {
  display: inline-block; }
  .card-group-control .card-title > a:before {
    content: "";
    font-family: "icomoon";
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .card-group-control .card-title > a.collapsed:before {
    content: ""; }

.card-group-control-left .card-title > a {
  padding-left: 1.625rem; }
  .card-group-control-left .card-title > a:before {
    left: 0; }

.card-group-control-right .card-title > a {
  padding-right: 1.625rem; }
  .card-group-control-right .card-title > a:before {
    right: 0; }

.card-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 1001;
  border-radius: 0.25rem;
  -webkit-animation: cardOverlayEnter 0.15s ease;
          animation: cardOverlayEnter 0.15s ease; }
  .card-overlay-fadeout {
    -webkit-animation: cardOverlayExit 0.15s ease;
            animation: cardOverlayExit 0.15s ease; }

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch; }

@-webkit-keyframes cardOverlayEnter {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes cardOverlayEnter {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes cardOverlayExit {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes cardOverlayExit {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* ------------------------------------------------------------------------------
 *
 *  # Breadcrumb component
 *
 *  Overrides for breadcrumb bootstrap component
 *
 * ---------------------------------------------------------------------------- */
.breadcrumb {
  padding: 0; }

.breadcrumb-item {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.625rem 0;
  color: inherit; }
  .breadcrumb-item i {
    font-size: 0.8125rem;
    top: 0; }

a.breadcrumb-item, a.breadcrumb-elements-item {
  color: inherit;
  transition: opacity ease-in-out 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    a.breadcrumb-item, a.breadcrumb-elements-item {
      transition: none; } }
  a.breadcrumb-item:hover, a.breadcrumb-elements-item:hover, a.breadcrumb-item:focus, a.breadcrumb-elements-item:focus,
  .show > a.breadcrumb-item,
  .show > a.breadcrumb-elements-item {
    opacity: 0.75; }

.page-header-content > .breadcrumb {
  padding-top: 0;
  padding-bottom: 1.25rem; }
  .page-header-content > .breadcrumb:first-child {
    padding-bottom: 0;
    padding-top: 1.25rem; }

.breadcrumb-dash > .breadcrumb-item + .breadcrumb-item:before {
  content: '\2013\00a0'; }

.breadcrumb-arrow > .breadcrumb-item + .breadcrumb-item:before {
  content: '\2192\00a0'; }
  [dir=rtl] .breadcrumb-arrow > .breadcrumb-item + .breadcrumb-item:before {
    content: '\2190\00a0'; }

.breadcrumb-arrows > .breadcrumb-item + .breadcrumb-item:before {
  content: '\00bb\00a0'; }

.breadcrumb-caret > .breadcrumb-item + .breadcrumb-item:before {
  content: '\203A\00a0'; }

.breadcrumb-line {
  position: relative;
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.breadcrumb-line-light {
  background-color: #fafafa;
  color: #333; }
  .page-header-light .breadcrumb-line-light:last-child {
    border-top: 1px solid #ddd; }

.breadcrumb-line-dark {
  background-color: #273246;
  color: #fff; }
  .page-header-dark .breadcrumb-line-dark:last-child {
    border-top: 1px solid rgba(255, 255, 255, 0.25); }
  .breadcrumb-line-dark .breadcrumb-item.active {
    color: inherit; }

.breadcrumb-elements-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.625rem 0; }
  .breadcrumb-elements-item + .breadcrumb-elements-item {
    margin-left: 1.25rem; }
  .breadcrumb-elements-item i {
    top: 0; }

/* ------------------------------------------------------------------------------
 *
 *  # Pagination component
 *
 *  Overrides for pagination component
 *
 * ---------------------------------------------------------------------------- */
.pagination {
  margin-bottom: 0; }

.page-link {
  text-align: center;
  min-width: 2.37506rem;
  transition: all ease-in-out 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }

.pagination-lg .page-link {
  min-width: 2.62503rem; }

.pagination-sm .page-link {
  min-width: 2.12507rem; }

.pagination-rounded .page-item:first-child .page-link {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem; }

.pagination-rounded .page-item:last-child .page-link {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem; }

.pagination-flat .page-item.active .page-link {
  background-color: #2196F3; }

.pagination-flat .page-item.disabled .page-link {
  border-color: transparent;
  background-color: transparent; }

.pagination-flat .page-link {
  background-color: transparent;
  border-color: transparent;
  margin-left: 2px;
  border-radius: 0.25rem; }
  .pagination-flat .page-link:hover, .pagination-flat .page-link:focus {
    background-color: #e1e5eb; }

.pagination-flat.pagination-rounded .page-link {
  border-radius: 50rem; }

.pagination-separated .page-link {
  margin-left: 0.625rem;
  border-radius: 0.25rem; }

.pagination-separated.pagination-rounded .page-link {
  border-radius: 50rem; }

.pagination-pager .page-item {
  border-radius: 0.25rem; }

.pagination-pager .page-link {
  margin-left: 1.25rem;
  border-radius: 0.25rem; }

.pagination-pager.pagination-rounded .page-link {
  border-radius: 50rem; }

.pagination-pager-linked .page-item.disabled .page-link {
  background-color: transparent;
  border-color: transparent; }

.pagination-pager-linked .page-link {
  color: #2196F3;
  background-color: transparent;
  border-color: transparent; }
  .pagination-pager-linked .page-link:hover, .pagination-pager-linked .page-link:focus {
    color: #fff;
    background-color: #2196F3;
    border-color: #2196F3; }

.pagination-pager:not(.pagination-rounded).pagination-lg .page-link {
  border-radius: 0.3125rem; }

.pagination-pager:not(.pagination-rounded).pagination-sm .page-link {
  border-radius: 0.1875rem; }

/* ------------------------------------------------------------------------------
 *
 *  # Badge component
 *
 *  Overrides for badge bootstrap component
 *
 * ---------------------------------------------------------------------------- */
.badge.dropdown-toggle:after {
  font-size: 85%; }

.btn .badge {
  top: 0; }

.badge-flat {
  background-color: transparent;
  border: 1px solid transparent;
  padding: calc(0.3125rem - 1px) calc(0.4375rem - 1px); }
  .badge-flat[href]:hover, .badge-flat[href]:focus {
    background-color: transparent;
    box-shadow: none; }
  .badge-flat.badge-pill {
    padding-left: calc(0.5rem - 1px);
    padding-right: calc(0.5rem - 1px); }

.badge-striped {
  padding: 0.4375rem 0.5rem;
  position: relative; }
  .badge-striped:before {
    content: '';
    width: 0;
    position: absolute;
    top: 0;
    bottom: 0; }
  .badge-striped-left {
    padding-left: calc(0.4375rem + 2px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
    .badge-striped-left:before {
      left: 0;
      border-left: 2px solid;
      border-left-color: inherit; }
  .badge-striped-right {
    padding-right: calc(0.4375rem + 2px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
    .badge-striped-right:before {
      right: 0;
      border-right: 2px solid;
      border-right-color: inherit; }

.badge-float {
  position: absolute;
  right: -0.5rem;
  top: -0.5rem; }

.badge-mark {
  padding: 0;
  border: 2px solid;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50rem; }
  .badge-mark:empty {
    display: inline-block; }
  [class*=icon-] > .badge-mark {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem; }

/* ------------------------------------------------------------------------------
 *
 *  # Alert component
 *
 *  Overrides for alert bootstrap component
 *
 * ---------------------------------------------------------------------------- */
.alert-dismissible .close:hover, .alert-dismissible .close:focus {
  color: inherit; }

.alert[class*=bg-]:not(.bg-transparent):not(.bg-white):not(.bg-light) .alert-link {
  color: inherit;
  border-bottom-color: rgba(255, 255, 255, 0.85); }

.alert-rounded {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  border-radius: 50rem; }
  .alert-rounded.alert-dismissible .close {
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem; }

.alert[class*=alert-styled-]:after {
  content: "";
  font-family: "icomoon";
  color: #fff;
  width: 3rem;
  left: -3rem;
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.alert[class*=alert-styled-].alert-danger:after, .alert[class*=alert-styled-][class*=bg-danger]:after {
  content: ""; }

.alert[class*=alert-styled-].alert-success:after, .alert[class*=alert-styled-][class*=bg-success]:after {
  content: ""; }

.alert[class*=alert-styled-].alert-warning:after, .alert[class*=alert-styled-][class*=bg-warning]:after {
  content: ""; }

.alert[class*=alert-styled-].alert-info:after, .alert[class*=alert-styled-][class*=bg-info]:after {
  content: ""; }

.alert.alert-styled-left {
  border-left-width: 3rem; }
  .alert.alert-styled-left[class*=bg-]:not(.bg-white):not(.bg-light) {
    border-left-color: rgba(0, 0, 0, 0.15) !important; }

.alert.alert-styled-right {
  border-right-width: 3rem; }
  .alert.alert-styled-right:after {
    left: auto;
    right: -3rem; }
  .alert.alert-styled-right[class*=bg-]:not(.bg-white):not(.bg-light) {
    border-right-color: rgba(0, 0, 0, 0.15) !important; }

.alert.alert-styled-custom:after {
  content: ""; }

.alert:not(.ui-pnotify)[class*=alert-arrow-]:before,
.alert.ui-pnotify[class*=alert-arrow-] > .brighttheme:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  border-left: 5px solid;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left-color: inherit;
  margin-top: -5px; }

.alert:not(.ui-pnotify).alert-arrow-right:before,
.alert.ui-pnotify.alert-arrow-right > .brighttheme:before {
  left: auto;
  right: 0;
  border-left: 0;
  border-right: 5px solid;
  border-right-color: inherit; }

/* ------------------------------------------------------------------------------
 *
 *  # Progress bar component
 *
 *  Overrides for progress bar component
 *
 * ---------------------------------------------------------------------------- */
.progress {
  position: relative; }

.progress-bar {
  width: 0;
  white-space: nowrap; }

.progress .progressbar-back-text,
.progress .progressbar-front-text {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  font-size: 0.75rem; }

.progress .progressbar-back-text {
  -ms-flex-item-align: center;
      align-self: center; }

.progress.right {
  -ms-flex-pack: end;
      justify-content: flex-end; }

.progress.vertical {
  width: 3rem;
  height: 100%;
  display: inline-block; }
  .progress.vertical + .progress.vertical {
    margin-left: 0.625rem; }
  .progress.vertical .progress-bar {
    width: 100%;
    height: 0;
    transition: height 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .progress.vertical .progress-bar {
        transition: none; } }
  .progress.vertical.bottom .progressbar-back-text,
  .progress.vertical.bottom .progressbar-front-text {
    top: auto;
    bottom: 0; }
  .progress.vertical.bottom .progress-bar {
    position: absolute;
    bottom: 0; }

/* ------------------------------------------------------------------------------
 *
 *  # Media object component
 *
 *  Overrides for media object component
 *
 * ---------------------------------------------------------------------------- */
.media {
  margin-top: 1.25rem; }
  .media.card-body, .media:first-child {
    margin-top: 0; }

.media-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; }

.media-title {
  margin-bottom: 0.125rem; }

.img-preview {
  max-height: 5rem; }

.media-list-bordered .media {
  margin-top: 0;
  padding: 1rem 1.25rem;
  border-top: 1px solid #ddd; }

.media-list-linked .media {
  padding: 1rem 1.25rem; }

.media-list-linked li.media {
  margin-top: 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd; }

.media-list-linked.media-list-bordered li.media {
  border-bottom: 0; }

.media-list-linked a.media {
  color: inherit;
  transition: background-color ease-in-out 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .media-list-linked a.media {
      transition: none; } }
  .media-list-linked a.media:hover {
    background-color: rgba(0, 0, 0, 0.04); }

/* ------------------------------------------------------------------------------
 *
 *  # List group component
 *
 *  Overrides for list group component
 *
 * ---------------------------------------------------------------------------- */
.list-group {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.5rem 0;
  background-clip: padding-box;
  border-radius: 0.25rem; }

.list-group-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  border-width: 0;
  background-color: transparent;
  transition: all ease-in-out 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .list-group-item {
      transition: none; } }
  .list-group:not([class*=list-group-horizontal]) .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group:not([class*=list-group-horizontal]) .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-item + .list-group-item {
    margin-top: 1px; }
    .list-group-item + .list-group-item.active {
      margin-top: 1px;
      border-top-width: 0; }
  .list-group .list-group-item + .list-group-item,
  .list-group .list-group-item + .list-group-item.active {
    margin-top: 0; }
  .list-group-item.disabled, .list-group-item:disabled {
    cursor: default; }

.list-group-item-active-indicator {
  opacity: 0;
  visibility: hidden;
  transition: opacity ease-in-out 0.15s, visibility ease-in-out 0.15s; }
  .list-group-item.active > .list-group-item-active-indicator {
    opacity: 1;
    visibility: visible; }

.list-group-horizontal {
  border: 0; }
  .list-group-horizontal .list-group-item-action {
    width: auto; }
  .list-group-horizontal .list-group-item {
    border: 1px solid rgba(0, 0, 0, 0.125); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    border: 0; }
    .list-group-horizontal-sm .list-group-item-action {
      width: auto; }
    .list-group-horizontal-sm .list-group-item {
      border: 1px solid rgba(0, 0, 0, 0.125); } }

@media (min-width: 769px) {
  .list-group-horizontal-md {
    border: 0; }
    .list-group-horizontal-md .list-group-item-action {
      width: auto; }
    .list-group-horizontal-md .list-group-item {
      border: 1px solid rgba(0, 0, 0, 0.125); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    border: 0; }
    .list-group-horizontal-lg .list-group-item-action {
      width: auto; }
    .list-group-horizontal-lg .list-group-item {
      border: 1px solid rgba(0, 0, 0, 0.125); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    border: 0; }
    .list-group-horizontal-xl .list-group-item-action {
      width: auto; }
    .list-group-horizontal-xl .list-group-item {
      border: 1px solid rgba(0, 0, 0, 0.125); } }

.list-group-flush > .list-group-item {
  border-width: 0; }

.list-group-divider {
  padding: 0;
  margin: 0.5rem 0;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.125);
  border: 0; }

.list-group-bordered {
  padding: 0; }
  .list-group-bordered .list-group-item {
    border-top-width: 1px;
    border-bottom-width: 1px;
    margin-bottom: -1px; }
    .list-group-bordered .list-group-item:first-child {
      border-top-width: 0;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem; }
    .list-group-bordered .list-group-item:last-child {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }

.list-group-custom {
  border-radius: 0.25rem; }
  .list-group-custom .list-group-item-action:not(.active):not(.disabled) {
    color: rgba(255, 255, 255, 0.9); }
    .list-group-custom .list-group-item-action:not(.active):not(.disabled):hover, .list-group-custom .list-group-item-action:not(.active):not(.disabled):focus {
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff; }
  .list-group-custom .list-group-item {
    color: #fff; }
    .list-group-custom .list-group-item.active {
      background-color: rgba(255, 255, 255, 0.2);
      border-color: transparent; }
    .list-group-custom .list-group-item.disabled, .list-group-custom .list-group-item:disabled {
      color: rgba(255, 255, 255, 0.6);
      background-color: transparent; }
  .list-group-custom .list-group-divider {
    background-color: rgba(255, 255, 255, 0.4); }
  .list-group-custom.list-group-bordered .list-group-item {
    border-top-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: rgba(255, 255, 255, 0.1); }

.list-group-item-primary {
  color: #114e7e;
  background-color: #c1e2fc; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #114e7e;
    background-color: #a9d7fb; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #114e7e;
    border-color: #114e7e; }

.list-group-item-primary-100 {
  color: #797f84;
  background-color: #f9fcff; }
  .list-group-item-primary-100.list-group-item-action:hover, .list-group-item-primary-100.list-group-item-action:focus {
    color: #797f84;
    background-color: #e0efff; }
  .list-group-item-primary-100.list-group-item-action.active {
    color: #fff;
    background-color: #797f84;
    border-color: #797f84; }

.list-group-item-secondary {
  color: #243c48;
  background-color: #cbd8de; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #243c48;
    background-color: #bbccd4; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #243c48;
    border-color: #243c48; }

.list-group-item-secondary-100 {
  color: #7b7d7e;
  background-color: #fafbfc; }
  .list-group-item-secondary-100.list-group-item-action:hover, .list-group-item-secondary-100.list-group-item-action:focus {
    color: #7b7d7e;
    background-color: #eaeef2; }
  .list-group-item-secondary-100.list-group-item-action.active {
    color: #fff;
    background-color: #7b7d7e;
    border-color: #7b7d7e; }

.list-group-item-success {
  color: #135d3b;
  background-color: #c2ead8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #135d3b;
    background-color: #afe3cc; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #135d3b;
    border-color: #135d3b; }

.list-group-item-success-100 {
  color: #79807d;
  background-color: #f9fdfb; }
  .list-group-item-success-100.list-group-item-action:hover, .list-group-item-success-100.list-group-item-action:focus {
    color: #79807d;
    background-color: #e6f7ee; }
  .list-group-item-success-100.list-group-item-action.active {
    color: #fff;
    background-color: #79807d;
    border-color: #79807d; }

.list-group-item-info {
  color: #17616a;
  background-color: #c4ecf1; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #17616a;
    background-color: #afe5ec; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #17616a;
    border-color: #17616a; }

.list-group-item-info-100 {
  color: #7a8182;
  background-color: #f9fdfe; }
  .list-group-item-info-100.list-group-item-action:hover, .list-group-item-info-100.list-group-item-action:focus {
    color: #7a8182;
    background-color: #e3f6fa; }
  .list-group-item-info-100.list-group-item-action.active {
    color: #fff;
    background-color: #7a8182;
    border-color: #7a8182; }

.list-group-item-warning {
  color: #7f4624;
  background-color: #fcddcb; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7f4624;
    background-color: #fbcdb3; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7f4624;
    border-color: #7f4624; }

.list-group-item-warning-100 {
  color: #847e7b;
  background-color: #fffcfa; }
  .list-group-item-warning-100.list-group-item-action:hover, .list-group-item-warning-100.list-group-item-action:focus {
    color: #847e7b;
    background-color: #ffede1; }
  .list-group-item-warning-100.list-group-item-action.active {
    color: #fff;
    background-color: #847e7b;
    border-color: #847e7b; }

.list-group-item-danger {
  color: #7c2b2a;
  background-color: #fbcfce; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7c2b2a;
    background-color: #f9b8b6; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7c2b2a;
    border-color: #7c2b2a; }

.list-group-item-danger-100 {
  color: #847c7c;
  background-color: #fefafa; }
  .list-group-item-danger-100.list-group-item-action:hover, .list-group-item-danger-100.list-group-item-action:focus {
    color: #847c7c;
    background-color: #fae5e5; }
  .list-group-item-danger-100.list-group-item-action.active {
    color: #fff;
    background-color: #847c7c;
    border-color: #847c7c; }

.list-group-item-white {
  color: #858585;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-light {
  color: #7d7e7f;
  background-color: #fbfbfc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7d7e7f;
    background-color: #ececf1; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7d7e7f;
    border-color: #7d7e7f; }

.list-group-item-light-100 {
  color: rgba(14, 14, 14, 0.532);
  background-color: rgba(255, 255, 255, 0.748); }
  .list-group-item-light-100.list-group-item-action:hover, .list-group-item-light-100.list-group-item-action:focus {
    color: rgba(14, 14, 14, 0.532);
    background-color: rgba(242, 242, 242, 0.748); }
  .list-group-item-light-100.list-group-item-action.active {
    color: #fff;
    background-color: rgba(14, 14, 14, 0.532);
    border-color: rgba(14, 14, 14, 0.532); }

.list-group-item-dark {
  color: #13161c;
  background-color: #c2c4c7; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #13161c;
    background-color: #b5b7bb; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #13161c;
    border-color: #13161c; }

.list-group-item-dark-100 {
  color: rgba(0, 0, 0, 0.532);
  background-color: rgba(250, 250, 250, 0.748); }
  .list-group-item-dark-100.list-group-item-action:hover, .list-group-item-dark-100.list-group-item-action:focus {
    color: rgba(0, 0, 0, 0.532);
    background-color: rgba(237, 237, 237, 0.748); }
  .list-group-item-dark-100.list-group-item-action.active {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.532);
    border-color: rgba(0, 0, 0, 0.532); }

.list-group-item-teal {
  color: #145650;
  background-color: #c2e6e3; }
  .list-group-item-teal.list-group-item-action:hover, .list-group-item-teal.list-group-item-action:focus {
    color: #145650;
    background-color: #b0dfdb; }
  .list-group-item-teal.list-group-item-action.active {
    color: #fff;
    background-color: #145650;
    border-color: #145650; }

.list-group-item-teal-100 {
  color: #79807f;
  background-color: #f9fcfc; }
  .list-group-item-teal-100.list-group-item-action:hover, .list-group-item-teal-100.list-group-item-action:focus {
    color: #79807f;
    background-color: #e8f4f4; }
  .list-group-item-teal-100.list-group-item-action.active {
    color: #fff;
    background-color: #79807f;
    border-color: #79807f; }

.list-group-item-indigo {
  color: #303864;
  background-color: #d1d6ed; }
  .list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
    color: #303864;
    background-color: #bfc6e6; }
  .list-group-item-indigo.list-group-item-action.active {
    color: #fff;
    background-color: #303864;
    border-color: #303864; }

.list-group-item-indigo-100 {
  color: #7c7d81;
  background-color: #fbfbfd; }
  .list-group-item-indigo-100.list-group-item-action:hover, .list-group-item-indigo-100.list-group-item-action:focus {
    color: #7c7d81;
    background-color: #eaeaf5; }
  .list-group-item-indigo-100.list-group-item-action.active {
    color: #fff;
    background-color: #7c7d81;
    border-color: #7c7d81; }

.list-group-item-purple {
  color: #4a3a64;
  background-color: #dfd7ee; }
  .list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
    color: #4a3a64;
    background-color: #d1c5e6; }
  .list-group-item-purple.list-group-item-action.active {
    color: #fff;
    background-color: #4a3a64;
    border-color: #4a3a64; }

.list-group-item-purple-100 {
  color: #7f7d81;
  background-color: #fcfbfd; }
  .list-group-item-purple-100.list-group-item-action:hover, .list-group-item-purple-100.list-group-item-action:focus {
    color: #7f7d81;
    background-color: #efeaf5; }
  .list-group-item-purple-100.list-group-item-action.active {
    color: #fff;
    background-color: #7f7d81;
    border-color: #7f7d81; }

.list-group-item-pink {
  color: #7e3046;
  background-color: #fcd1dd; }
  .list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
    color: #7e3046;
    background-color: #fab9cb; }
  .list-group-item-pink.list-group-item-action.active {
    color: #fff;
    background-color: #7e3046;
    border-color: #7e3046; }

.list-group-item-pink-100 {
  color: #847c7e;
  background-color: #fffbfc; }
  .list-group-item-pink-100.list-group-item-action:hover, .list-group-item-pink-100.list-group-item-action:focus {
    color: #847c7e;
    background-color: #ffe2e9; }
  .list-group-item-pink-100.list-group-item-action.active {
    color: #fff;
    background-color: #847c7e;
    border-color: #847c7e; }

.list-group-item-yellow {
  color: #856f25;
  background-color: #fff4cc; }
  .list-group-item-yellow.list-group-item-action:hover, .list-group-item-yellow.list-group-item-action:focus {
    color: #856f25;
    background-color: #ffefb3; }
  .list-group-item-yellow.list-group-item-action.active {
    color: #fff;
    background-color: #856f25;
    border-color: #856f25; }

.list-group-item-yellow-100 {
  color: #85837b;
  background-color: #fffefa; }
  .list-group-item-yellow-100.list-group-item-action:hover, .list-group-item-yellow-100.list-group-item-action:focus {
    color: #85837b;
    background-color: #fff9e1; }
  .list-group-item-yellow-100.list-group-item-action.active {
    color: #fff;
    background-color: #85837b;
    border-color: #85837b; }

/* ------------------------------------------------------------------------------
 *
 *  # Close button
 *
 *  Overrides of default close button styles
 *
 * ---------------------------------------------------------------------------- */
.close {
  opacity: .75;
  transition: color ease-in-out 0.15s, opacity ease-in-out 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .close {
      transition: none; } }
  .close:hover, .close:focus {
    outline: 0; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: 1; }

/* ------------------------------------------------------------------------------
 *
 *  # Tooltip component
 *
 *  Override and extend default tooltip styles.
 *
 * ---------------------------------------------------------------------------- */
.toast-header {
  background-clip: border-box; }
  .toast-header .close {
    color: inherit; }

/* ------------------------------------------------------------------------------
 *
 *  # Modal component
 *
 *  Overrides for modal dialog component
 *
 * ---------------------------------------------------------------------------- */
.modal-header {
  position: relative; }
  .modal-header .close {
    color: inherit;
    -ms-flex-item-align: stretch;
        align-self: stretch; }
  .modal-header:not([class*=bg-]) {
    padding-bottom: 0;
    border-bottom-width: 0; }

.modal-footer:not([class*=bg-]) {
  padding-top: 0;
  border-top-width: 0; }

[class*=table-responsive] + .modal-footer,
.table + .modal-footer {
  border-top-width: 1px;
  border-top-color: #ddd; }

.modal-footer > * {
  margin: 0; }

.modal-footer > .btn + .btn {
  margin-left: 1.25rem; }

@media (min-width: 576px) {
  .modal-xs {
    max-width: 300px; }
  .modal-full {
    max-width: 94%;
    margin-left: 3%;
    margin-right: 3%; } }

.modal-left .modal-dialog,
.modal-right .modal-dialog,
.modal-top .modal-dialog,
.modal-bottom .modal-dialog {
  margin: 0; }

.modal-left .modal-content,
.modal-right .modal-content,
.modal-top .modal-content,
.modal-bottom .modal-content {
  border: 0; }

.modal-left .modal-content,
.modal-left .modal-header,
.modal-left .modal-footer,
.modal-right .modal-content,
.modal-right .modal-header,
.modal-right .modal-footer,
.modal-top .modal-content,
.modal-top .modal-header,
.modal-top .modal-footer,
.modal-bottom .modal-content,
.modal-bottom .modal-header,
.modal-bottom .modal-footer {
  border-radius: 0; }

.modal-left .modal-dialog,
.modal-right .modal-dialog {
  width: 320px;
  height: 100%; }

.modal-left.fade .modal-dialog,
.modal-right.fade .modal-dialog {
  transition: -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .modal-left.fade .modal-dialog,
    .modal-right.fade .modal-dialog {
      transition: none; } }

.modal-left .modal-dialog-scrollable,
.modal-left .modal-dialog-scrollable .modal-content,
.modal-right .modal-dialog-scrollable,
.modal-right .modal-dialog-scrollable .modal-content {
  max-height: none; }

.modal-left .modal-content,
.modal-right .modal-content {
  height: 100%; }

.modal-left .modal-xs,
.modal-right .modal-xs {
  width: 56px; }

.modal-left .modal-sm,
.modal-right .modal-sm {
  width: 156px; }

@media (min-width: 576px) {
  .modal-left .modal-lg,
  .modal-right .modal-lg {
    width: 400px; } }

.modal-left.modal .modal-dialog {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.modal-left.show .modal-dialog {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.modal-right .modal-dialog {
  margin-left: auto; }

.modal-right.modal .modal-dialog {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.modal-right.show .modal-dialog {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.modal-top .modal-dialog,
.modal-bottom .modal-dialog {
  width: 100%;
  max-width: none; }

.modal-top.modal .modal-dialog,
.modal-bottom.modal .modal-dialog {
  -webkit-transform: none;
          transform: none; }

.modal-top.fade .modal-content,
.modal-bottom.fade .modal-content {
  transition: -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .modal-top.fade .modal-content,
    .modal-bottom.fade .modal-content {
      transition: none; } }

.modal-top.modal .modal-content {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%); }

.modal-top.show .modal-content {
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.modal-bottom.modal .modal-content {
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.modal-bottom.show .modal-content {
  -webkit-transform: translateY(0);
          transform: translateY(0); }

/* ------------------------------------------------------------------------------
 *
 *  # Tooltip component
 *
 *  Override and extend default tooltip styles.
 *
 * ---------------------------------------------------------------------------- */
.bs-tooltip-top .arrow[class*=border-]:before {
  border-top-color: inherit; }

.bs-tooltip-right .arrow[class*=border-]:before {
  border-right-color: inherit; }

.bs-tooltip-bottom .arrow[class*=border-]:before {
  border-bottom-color: inherit; }

.bs-tooltip-left .arrow[class*=border-]:before {
  border-left-color: inherit; }

/* ------------------------------------------------------------------------------
 *
 *  # Popover component
 *
 *  Overrides for popover component
 *
 * ---------------------------------------------------------------------------- */
.popover-header {
  font-weight: 500;
  padding-bottom: 0;
  border-bottom-width: 0; }
  .popover-header[class*=bg-] {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom-width: 1px; }

.bs-popover-top[class*=bg-] .arrow, .bs-popover-top[class*=bg-] .arrow:after, .bs-popover-top[class*=bg-] .arrow:before, .bs-popover-top[class*=border-] .arrow, .bs-popover-top[class*=border-] .arrow:after, .bs-popover-top[class*=border-] .arrow:before {
  border-top-color: inherit; }

.bs-popover-right[class*=bg-] .arrow, .bs-popover-right[class*=bg-] .arrow:after, .bs-popover-right[class*=bg-] .arrow:before, .bs-popover-right[class*=border-] .arrow, .bs-popover-right[class*=border-] .arrow:after, .bs-popover-right[class*=border-] .arrow:before {
  border-right-color: inherit; }

.bs-popover-bottom[class*=bg-] .arrow, .bs-popover-bottom[class*=bg-] .arrow:after, .bs-popover-bottom[class*=bg-] .arrow:before, .bs-popover-bottom[class*=border-] .arrow, .bs-popover-bottom[class*=border-] .arrow:after, .bs-popover-bottom[class*=border-] .arrow:before {
  border-bottom-color: inherit; }

.bs-popover-left[class*=bg-] .arrow, .bs-popover-left[class*=bg-] .arrow:after, .bs-popover-left[class*=bg-] .arrow:before, .bs-popover-left[class*=border-] .arrow, .bs-popover-left[class*=border-] .arrow:after, .bs-popover-left[class*=border-] .arrow:before {
  border-left-color: inherit; }

/* ------------------------------------------------------------------------------
 *
 *  # Utilities
 *
 *  Import Bootstrap utilities with overrides
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Border utility
 *
 *  Override and extend default border utility.
 *
 * ---------------------------------------------------------------------------- */
.border-top-primary {
  border-top-color: #2196F3 !important; }

.border-bottom-primary {
  border-bottom-color: #2196F3 !important; }

.border-left-primary {
  border-left-color: #2196F3 !important; }

.border-right-primary {
  border-right-color: #2196F3 !important; }

.border-top-primary-100 {
  border-top-color: #e9f5fe !important; }

.border-bottom-primary-100 {
  border-bottom-color: #e9f5fe !important; }

.border-left-primary-100 {
  border-left-color: #e9f5fe !important; }

.border-right-primary-100 {
  border-right-color: #e9f5fe !important; }

.border-top-secondary {
  border-top-color: #45748a !important; }

.border-bottom-secondary {
  border-bottom-color: #45748a !important; }

.border-left-secondary {
  border-left-color: #45748a !important; }

.border-right-secondary {
  border-right-color: #45748a !important; }

.border-top-secondary-100 {
  border-top-color: #ecf1f3 !important; }

.border-bottom-secondary-100 {
  border-bottom-color: #ecf1f3 !important; }

.border-left-secondary-100 {
  border-left-color: #ecf1f3 !important; }

.border-right-secondary-100 {
  border-right-color: #ecf1f3 !important; }

.border-top-success {
  border-top-color: #25b372 !important; }

.border-bottom-success {
  border-bottom-color: #25b372 !important; }

.border-left-success {
  border-left-color: #25b372 !important; }

.border-right-success {
  border-right-color: #25b372 !important; }

.border-top-success-100 {
  border-top-color: #e9f7f1 !important; }

.border-bottom-success-100 {
  border-bottom-color: #e9f7f1 !important; }

.border-left-success-100 {
  border-left-color: #e9f7f1 !important; }

.border-right-success-100 {
  border-right-color: #e9f7f1 !important; }

.border-top-info {
  border-top-color: #2cbacc !important; }

.border-bottom-info {
  border-bottom-color: #2cbacc !important; }

.border-left-info {
  border-left-color: #2cbacc !important; }

.border-right-info {
  border-right-color: #2cbacc !important; }

.border-top-info-100 {
  border-top-color: #eaf8fa !important; }

.border-bottom-info-100 {
  border-bottom-color: #eaf8fa !important; }

.border-left-info-100 {
  border-left-color: #eaf8fa !important; }

.border-right-info-100 {
  border-right-color: #eaf8fa !important; }

.border-top-warning {
  border-top-color: #f58646 !important; }

.border-bottom-warning {
  border-bottom-color: #f58646 !important; }

.border-left-warning {
  border-left-color: #f58646 !important; }

.border-right-warning {
  border-right-color: #f58646 !important; }

.border-top-warning-100 {
  border-top-color: #fef3ed !important; }

.border-bottom-warning-100 {
  border-bottom-color: #fef3ed !important; }

.border-left-warning-100 {
  border-left-color: #fef3ed !important; }

.border-right-warning-100 {
  border-right-color: #fef3ed !important; }

.border-top-danger {
  border-top-color: #ef5350 !important; }

.border-bottom-danger {
  border-bottom-color: #ef5350 !important; }

.border-left-danger {
  border-left-color: #ef5350 !important; }

.border-right-danger {
  border-right-color: #ef5350 !important; }

.border-top-danger-100 {
  border-top-color: #fdeeee !important; }

.border-bottom-danger-100 {
  border-bottom-color: #fdeeee !important; }

.border-left-danger-100 {
  border-left-color: #fdeeee !important; }

.border-right-danger-100 {
  border-right-color: #fdeeee !important; }

.border-top-white {
  border-top-color: #fff !important; }

.border-bottom-white {
  border-bottom-color: #fff !important; }

.border-left-white {
  border-left-color: #fff !important; }

.border-right-white {
  border-right-color: #fff !important; }

.border-top-light {
  border-top-color: #f0f2f5 !important; }

.border-bottom-light {
  border-bottom-color: #f0f2f5 !important; }

.border-left-light {
  border-left-color: #f0f2f5 !important; }

.border-right-light {
  border-right-color: #f0f2f5 !important; }

.border-top-light-100 {
  border-top-color: rgba(255, 255, 255, 0.1) !important; }

.border-bottom-light-100 {
  border-bottom-color: rgba(255, 255, 255, 0.1) !important; }

.border-left-light-100 {
  border-left-color: rgba(255, 255, 255, 0.1) !important; }

.border-right-light-100 {
  border-right-color: rgba(255, 255, 255, 0.1) !important; }

.border-top-dark {
  border-top-color: #252b36 !important; }

.border-bottom-dark {
  border-bottom-color: #252b36 !important; }

.border-left-dark {
  border-left-color: #252b36 !important; }

.border-right-dark {
  border-right-color: #252b36 !important; }

.border-top-dark-100 {
  border-top-color: rgba(0, 0, 0, 0.1) !important; }

.border-bottom-dark-100 {
  border-bottom-color: rgba(0, 0, 0, 0.1) !important; }

.border-left-dark-100 {
  border-left-color: rgba(0, 0, 0, 0.1) !important; }

.border-right-dark-100 {
  border-right-color: rgba(0, 0, 0, 0.1) !important; }

.border-top-teal {
  border-top-color: #26A69A !important; }

.border-bottom-teal {
  border-bottom-color: #26A69A !important; }

.border-left-teal {
  border-left-color: #26A69A !important; }

.border-right-teal {
  border-right-color: #26A69A !important; }

.border-top-teal-100 {
  border-top-color: #e9f6f5 !important; }

.border-bottom-teal-100 {
  border-bottom-color: #e9f6f5 !important; }

.border-left-teal-100 {
  border-left-color: #e9f6f5 !important; }

.border-right-teal-100 {
  border-right-color: #e9f6f5 !important; }

.border-top-indigo {
  border-top-color: #5C6BC0 !important; }

.border-bottom-indigo {
  border-bottom-color: #5C6BC0 !important; }

.border-left-indigo {
  border-left-color: #5C6BC0 !important; }

.border-right-indigo {
  border-right-color: #5C6BC0 !important; }

.border-top-indigo-100 {
  border-top-color: #eff0f9 !important; }

.border-bottom-indigo-100 {
  border-bottom-color: #eff0f9 !important; }

.border-left-indigo-100 {
  border-left-color: #eff0f9 !important; }

.border-right-indigo-100 {
  border-right-color: #eff0f9 !important; }

.border-top-purple {
  border-top-color: #8e70c1 !important; }

.border-bottom-purple {
  border-bottom-color: #8e70c1 !important; }

.border-left-purple {
  border-left-color: #8e70c1 !important; }

.border-right-purple {
  border-right-color: #8e70c1 !important; }

.border-top-purple-100 {
  border-top-color: #f4f1f9 !important; }

.border-bottom-purple-100 {
  border-bottom-color: #f4f1f9 !important; }

.border-left-purple-100 {
  border-left-color: #f4f1f9 !important; }

.border-right-purple-100 {
  border-right-color: #f4f1f9 !important; }

.border-top-pink {
  border-top-color: #f35c86 !important; }

.border-bottom-pink {
  border-bottom-color: #f35c86 !important; }

.border-left-pink {
  border-left-color: #f35c86 !important; }

.border-right-pink {
  border-right-color: #f35c86 !important; }

.border-top-pink-100 {
  border-top-color: #feeff3 !important; }

.border-bottom-pink-100 {
  border-bottom-color: #feeff3 !important; }

.border-left-pink-100 {
  border-left-color: #feeff3 !important; }

.border-right-pink-100 {
  border-right-color: #feeff3 !important; }

.border-top-yellow {
  border-top-color: #ffd648 !important; }

.border-bottom-yellow {
  border-bottom-color: #ffd648 !important; }

.border-left-yellow {
  border-left-color: #ffd648 !important; }

.border-right-yellow {
  border-right-color: #ffd648 !important; }

.border-top-yellow-100 {
  border-top-color: #fffbed !important; }

.border-bottom-yellow-100 {
  border-bottom-color: #fffbed !important; }

.border-left-yellow-100 {
  border-left-color: #fffbed !important; }

.border-right-yellow-100 {
  border-right-color: #fffbed !important; }
