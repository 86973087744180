iframe {
    border: 1px solid #e2e2e2;
    width: 100%;
    height: 100%;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
}
.card-mark {
    border-top: 4px solid #3682a5!important;
}
.card-mark-green {
    border-top: 4px solid #46a537 !important;
}
.card-mark-brown {
    border-top: 4px solid #A1887F !important;
}
.bg-metric {
    background-color: #77777726
}
.bg-reference {
    background-color: #2b66a30d
}

.loader {
    width:100%;
    height:100%;
    z-index:1000;
    background:#fff;
    top:0;
    left:0;
}
.loader-full {
    position: absolute;
    width:100%;
    height:100vh;
    top:0;
    left:0;
    z-index: 1001;
    background-color: #f5f5f5;
}
.loader-content {
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index: 100;
    background-color: #f5f5f5;
}
.loader-modal {
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index: 100;
    background-color: #f5f5f5;
}
.loader-view {
    position: relative;
    width:100%;
    height:92vh;
    top:0;
    left:0;
    z-index: 100;
    background-color: #f5f5f5;
}
.loader-card {
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index: 50;
    background-color: #f5f5f5;
}

/* BOX LAYOUT */
.box-panel-title {
    color: #9e9e9e;
    font-size: 15px;
    font-weight: bold;
}
.box-panel-info {
    color: #9e9e9e;
    font-size: 12px;
    font-weight: bold;
}
.box-model {
    position: relative;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-radius: 2px;
    width: 200px;
    box-shadow: 0 3px 8px -3px rgba(92, 92, 92, 0.31);
    margin-left: 20px;
    margin-bottom: 20px;
    z-index: 50;
    box-sizing: border-box;
}
.box-selected {
    border: 3px solid #647b6d;
}
@media (max-width: 768px) {
    .box-model {
        position: relative!important;
    }
}
.box-model:hover{
    box-shadow: 0 10px 13px -3px rgba(92, 92, 92, 0.31);
}

.box-model .box-header {
    text-align: left;
    padding: 10px;
    height: 90px;
    border-bottom: 1px solid #d8d8d8;
    /*background-color: #36a56819;*/
    background-color: #f0f2f5;
    cursor: pointer;
}

.box-model .box-info {
    text-align: left;
    padding: 8px;
    height: 60px;
    border-bottom: 1px solid #d8d8d8;
}

.box-model .box-descricao {
    text-align: left;
    padding-top: 3px;
}

.box-model .box-action {
    width: 100%;
    padding: 10px;
    background-color: #fcfcfc;
    border-bottom: 1px solid #d8d8d8;
    text-align: center;
}
.box-model .box-action .btn-default{
    background-color: #e2e2e2;
    border-color: #fff;
}

.box-model .box-loading {
    display: none;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 247px;
    /*height: 160px;*/
    background-color: #fff;
    opacity: 0.8;
    cursor: auto!important;
}

.box-model .box-loading .box-loading-img {
    text-align: center;
    width: 100%;
    position: relative;
}

.tabulator {
    /*font-size: 12px!important;*/
}
.tabulator .tabulator-footer {
    border: 0!important;
}
.tabulator-row .tabulator-cell {
    padding: 1px!important;
}
.tabulator-cell-error {
    border-left: 2px solid #F44336
}

/* Primereact */
.p-component {
    font-size: 0.8rem
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.3rem 0.3rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 0.3rem 0.3rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.3rem 0.3rem;
}

.p-datepicker {
    z-index: 1100!important;
}

.p-inputtext.p-inputtext-sm {
    font-size: 0.875rem !important;
    padding: 0.6rem 0.6rem 0.6rem 0.6rem;
    line-height: 0
}

.p-inputnumber-input {
    font-size: 0.875rem;
    padding: 0.45625rem 0.45625rem 0.45625rem 0.65625rem;
}
.p-inputmask {
    font-size: 0.875rem;
    padding: 0.45625rem 0.45625rem 0.45625rem 0.65625rem;
}

.p-dropdown-label {
    font-size: 0.875rem !important;
    padding: 0.5rem 0.6rem 0.5rem 0.7rem
}

/*.p-inputtext {*/
/*    padding: 0.1rem 0.3rem 0 0.7rem;*/
/*    font-size: 0.8rem*/
/*}*/
/*.p-inputtext-sm {*/
/*    padding: 0.3rem 0.3rem 0.3rem 2.5rem;*/
/*}*/

.p-paginator {
    font-size: 12px; /* Reduz o tamanho da fonte */
    padding: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    padding: 4px 8px; /* Reduz o padding dos botões de página */
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    padding: 4px 8px; /* Reduz o padding dos botões de navegação */
}
.p-paginator .p-dropdown {
    margin-left: 0.5rem;
    height: 2.4rem;
}
.p-dropdown-panel {
    z-index: 1101!important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0.2rem 1rem;
}
.p-dropdown .p-dropdown-trigger {
    width: 2rem;
}

.custom-dropdown-sm .p-dropdown {
    font-size: 0.85rem;
    padding: 0.25rem 0.5rem;
}

.custom-dropdown-sm .p-dropdown-label {
    font-size: 0.85rem;
    padding: 0.25rem 0.5rem;
}

.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.1);
}
.p-datepicker table td > span {
     width: 2rem;
     height: 2rem;
     border-radius: 40%;
     transition: box-shadow 0.2s;
     border: 1px solid transparent;
}
.p-colorpicker-panel {
    z-index:1050!important;
}
.p-multiselect-panel {
    z-index:1050!important;
}
.p-multiselect .p-multiselect-label {
    padding: 0.51rem 0.7rem;
}
.p-multiselect-panel .p-multiselect-header {
    padding: 0.5rem 1.25rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.5rem 1.25rem;
}

.dropzone {
    flex: 1;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.custom-tooltip-timeline {
    background-color: #efefef;
    padding: 10px;
    font-size: 12px;
    width: 300px;
    word-wrap: break-word;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}
