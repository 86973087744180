.tabulator  {
    font-size: 12px;
}
.tabulator .tabulator-row {
    height: 22px;
}
.tabulator-row.tabulator-group {
    padding: 0 0 0 10px;
}
.tabulator .tabulator-footer {
    border: 0!important;
}
.tabulator-row .tabulator-cell {
    padding: 1px!important;
    padding-left: 3px!important;
}
.tabulator .tabulator-header .tabulator-col .tabulator-header-filter input{
    outline-offset:0;
    padding: 0!important;
    font-size: 12px;
    line-height: 1.6924;
    border-radius: 0.1875rem;
}
.tabulator-row .tabulator-cell.tabulator-editing {
    border: 1px solid #3f70b9
}

.tabulator .tabulator-header {
    border-bottom: 1px solid #f0f0f0;
}